// // import React from "react";

// // const DateFormatter = ({ dateString }) => {
// //   const formatDate = (dateString) => {
// //     if (!dateString) {
// //       return "Not Available";
// //     }
// //     const date = new Date(dateString);
// //     const year = date.getFullYear();
// //     const month = date.getMonth() + 1;
// //     const day = date.getDate();
// //     return `${year}-${month}-${day}`;
// //   };

// //   return <>{formatDate(dateString)}</>;
// // };

// // export default DateFormatter;

// const formatDate = (dateString) => {
//   if (!dateString) {
//     return "Not Available";
//   }

//   const date = new Date(dateString);
//   if (isNaN(date.getTime())) {
//     return "No Downlines Yet";
//   }

//   // const date = new Date(dateString);
//   const year = date.getFullYear();
//   const month = String(date.getMonth() + 1).padStart(2, "0");
//   const day = String(date.getDate()).padStart(2, "0");
//   const hours = String(date.getHours() - 1).padStart(2, "0");
//   const minutes = String(date.getMinutes()).padStart(2, "0");
//   const seconds = String(date.getSeconds()).padStart(2, "0");
//   return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
// };

// export default formatDate;

// const formatDate = (dateString) => {
//   if (!dateString) {
//     return "Not Available";
//   }

//   const date = new Date(dateString);
//   if (isNaN(date.getTime())) {
//     return "No Downlines Yet";
//   }

//   // const date = new Date(dateString);
//   const year = date.getFullYear();
//   const month = String(date.getMonth() + 1).padStart(2, "0");
//   const day = String(date.getDate()).padStart(2, "0");
//   const hours = String(date.getHours() - 1).padStart(2, "0");
//   const minutes = String(date.getMinutes()).padStart(2, "0");
//   const seconds = String(date.getSeconds()).padStart(2, "0");
//   return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
// };

// export default formatDate;

const formatDate = (dateString) => {
  if (!dateString) {
    return "Not Available";
  }

  const date = new Date(dateString);
  // console.log("date", date);
  if (isNaN(date.getTime())) {
    return "No Downlines Yet";
  }

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  // Return in 'd-m-Y H:i:s' format
  return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
};

export const formatTimestamp = (createdAt) => {
  // Get the current date and time

  // Define the months array
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Format day with suffix (st, nd, rd, th)
  const day = createdAt.getDate();
  const daySuffix = (day) => {
    if (day > 3 && day < 21) return "th"; // catches 11th - 19th
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  // Format the time in 24-hour format with two digits for minutes
  const hours = createdAt.getHours();
  const minutes = createdAt.getMinutes().toString().padStart(2, "0");
  const year = createdAt.getFullYear();

  // Construct the formatted date string
  const formattedDate = `${months[createdAt.getMonth()]} ${day}${daySuffix(
    day
  )} ${year}, ${hours}:${minutes}`;

  return formattedDate;
};

export function formatDbDate(dateString) {
  const date = new Date(dateString);

  // Check if the date is valid
  if (isNaN(date.getTime())) {
    return "Invalid Date"; // Handle invalid date inputs
  }

  // Options for formatting (e.g., Month Day, Year format)
  const options = {
    year: "numeric",
    month: "long", // 'long' gives full month name like "September"
    day: "numeric",
    hour: "2-digit", // Adds hours to the format
    minute: "2-digit", // Adds minutes to the format
    second: "2-digit", // Adds seconds to the format
    hour12: true, // Shows time in 12-hour format (AM/PM)
  };

  // Format the date as 'Month Day, Year, Time AM/PM'
  return date.toLocaleString(undefined, options);
}

export default formatDate;
