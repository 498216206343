import { useState, useEffect } from "react";
import MainLayouts from "../../Layout/MainLayouts";
import { ReactComponent as ArrowLeftSquare } from "bootstrap-icons/icons/arrow-left-square-fill.svg";
import Spinner from "../spinner/Spinner";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { ReactComponent as ChevronRightIcon } from "bootstrap-icons/icons/chevron-right.svg";
import { ReactComponent as BiNewspaper } from "bootstrap-icons/icons/newspaper.svg";
import { ReactComponent as List } from "bootstrap-icons/icons/list.svg";
import axios from "axios";
import ApiServices from "../../api/ApiServices";
import { toast } from "react-toastify";
import { handleError } from "../../components/utils/AuthenticationCheck";
import refresh from "../../assets/refresh.svg";
const VendorDetails = () => {
  const [vendorDetails, setVendorDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [balance, setBalance] = useState("Not available");
  const navigateTo = useNavigate();
  const location = useLocation();
  const { vendor_id } = location.state || {};
  // console.log("this is vendor id", vendor_id);
  const getBalance = async () => {
    setLoading(true);
    try {
      const authData = JSON.parse(localStorage.getItem("auth"));
      const token = authData?.token;
      if (!token) {
        throw new Error("No access token found.");
      }

      const response = await axios.get(ApiServices.getVendorBalance, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        params: { vendor_id: vendor_id },
        withCredentials: true,
      });

      const { status, message, data } = response.data;
      console.log("vendor", vendor_id);
      console.log("this is is the customer data from state", data);
      if (status === 200) {
        console.log("vendor", vendor_id);
        console.log("this is is the customer data from state", data);
        setBalance(data || "");
        toast.success(message, {
          toastId: "customer-details-fetched-successfully",
        });
      } else {
        handleError({ response: { status, data: { message } } }, navigateTo);
      }
    } catch (error) {
      handleError(error, navigateTo);
      // console.error("Error fetching customer details:", error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getBalance();
  }, []);

  useEffect(() => {
    const fetchVendorDetails = async () => {
      setLoading(true);
      try {
        const authData = JSON.parse(localStorage.getItem("auth"));
        const token = authData?.token;
        if (!token) {
          throw new Error("No access token found.");
        }

        const response = await axios.get(ApiServices.getVendorById, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          params: { vendor_id },
          withCredentials: true,
        });

        const { status, message, data } = response.data;

        if (status === 200) {
          // console.log("this is is the vendor data from state", data);
          setVendorDetails(data || {});
          toast.success(message, {
            toastId: "vendor-details-fetch-successfully",
          });
        } else {
          handleError({ response: { status, data: { message } } }, navigateTo);
        }
      } catch (error) {
        handleError(error, navigateTo);
      } finally {
        setLoading(false);
      }
    };

    if (vendor_id) {
      fetchVendorDetails();
    }
  }, [vendor_id, navigateTo]);

  return (
    <div>
      <MainLayouts>
        <div className="relative overflow-x-auto sm:rounded-lg py-6 w-full">
          {/* <h1 className="text-dash md:text-lg">Customer Details</h1>
           */}
          <section
            className="w-full pl-[3%] pr-[3%] pt-3 my-3"
            style={{ backgroundColor: "#F7F7F7" }}
          >
            <nav className="bg-gray-200 py-0 px-4 rounded-lg ">
              <ol className="list-none p-0 inline-flex mb-2">
                <li className="flex items-center">
                  <p className="text-blue-500 hover:text-blue-600 text-darkGreen3 pr-2">
                    Vendor
                  </p>
                  <ChevronRightIcon />
                </li>
                <li className="flex items-center">
                  <p className="text-orange1">Details</p>
                </li>
              </ol>
            </nav>
          </section>
          {/* <div className="ml-auto w-[25%] flex justify-end ">
            <Link
              to="/admin/vendor/list"
              className="w-full sm:w-auto text-dGrey3 bg-darkGreen3 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            >
              <span className="flex justify-center sm:justify-start mx-2">
                <ArrowLeftSquare className="mr-2" />
                Back
              </span>
            </Link>
          </div> */}

          {loading ? (
            <Spinner loading={loading} />
          ) : (
            <section className="text-gray-600 body-font">
              {/* <div className="ml-auto w-[25%] flex justify-end">
                <Link
                  to="/admin/vendor/list"
                  className="w-full sm:w-auto text-dGrey3 bg-darkGreen3 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                >
                  <span className="flex justify-center sm:justify-start mx-2">
                    <ArrowLeftSquare className="mr-2" />
                    Back
                  </span>
                </Link>
              </div> */}
              <div className="container px-1 py-1 mx-auto">
                <div className="flex flex-wrap -m-2">
                  <div className="p-2 w-full">
                    <div className="h-full flex   border-grey3 border items-center p-4 rounded-lg">
                      {/* <img
                        alt="team"
                        className="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4 hidden md:block "
                        src={`https://ui-avatars.com/api/?name=${encodeURIComponent(
                          vendorDetails.firstname
                            ? vendorDetails.firstname
                            : "Not Available"
                        )}`}
                      /> */}
                      <div className="flex-grow hidden md:block ">
                        <h2 className="text-dash title-font font-medium">
                          {vendorDetails.lastname && vendorDetails.firstname
                            ? `${vendorDetails.lastname} ${vendorDetails.firstname}`
                            : "Not Available"}
                        </h2>
                        <p className="text-gray-500 hidden md:block ">
                          <span className="text-gray-500">Status : </span>
                          <span className="text-dash">
                            {vendorDetails.status
                              ? vendorDetails.status
                              : "Not Available"}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="px-2  w-full">
                    <div className="h-full flex items-center border-dash border p-2  rounded-lg border-l-4">
                      <div className="w-full  pb-6">
                        <div className="flex  border-gray-200 py-2 ">
                          <span className="text-gray-500">Email Address</span>
                          <span className="ml-auto text-dash">
                            {vendorDetails.email
                              ? vendorDetails.email
                              : "Not Available"}
                          </span>
                        </div>

                        <div className="flex border-t border-grey3 py-2 ">
                          <span className="text-gray-500">Phone</span>
                          <span className="ml-auto text-dash">
                            {vendorDetails.phone_number
                              ? vendorDetails.phone_number
                              : "Not Available"}
                          </span>
                        </div>

                        <div className="flex border-t border-grey3 py-2 justify-between">
                          <span className="text-gray-500">Wallet balance </span>
                          {/* <span className="ml-auto text-dash">
                            &nbsp;
                            {customerDetails.address
                              ? customerDetails.address
                              : "Not Available"}
                          </span> */}
                          <div className="text-[16px] leading-[25.6px] lg:text-xl whitespace-nowrap flex items-center gap-2 lg:gap-5 text-dash">
                            <img
                              src={refresh}
                              alt=""
                              className={`cursor-pointer ${
                                loading && "animate-spin"
                              } `}
                              onClick={getBalance}
                            />
                            &#8358; {balance}
                          </div>
                        </div>
                        <div className="flex border-t border-grey3 py-2 relative">
                          <span className="text-gray-500">
                            {" "}
                            Customer Transactions
                          </span>
                          <button
                            className="ml-auto sm:w-auto text-dGrey3 bg-orange1  focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                            onClick={() => {
                              navigateTo("/admin/vendor/history", {
                                state: { id: vendor_id },
                                replace: true,
                              });
                            }}
                          >
                            <span className="flex justify-center sm:justify-start mx-2">
                              {/* <ArrowLeftSquare className="mr-2" /> */}
                              <BiNewspaper className="mr-2" />
                              View
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ml-auto w-[25%] flex justify-end my-4">
                    <Link
                      to="/admin/vendor/list"
                      className="w-full sm:w-auto text-dGrey3 bg-darkGreen3 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                    >
                      <span className="flex justify-center sm:justify-start mx-2">
                        <ArrowLeftSquare className="mr-2" />
                        Back
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </section>
          )}
        </div>
      </MainLayouts>
    </div>
  );
};

export default VendorDetails;

// import { useState, useEffect } from "react";
// import MainLayouts from "../../Layout/MainLayouts";
// import { ReactComponent as ArrowLeftSquare } from "bootstrap-icons/icons/arrow-left-square-fill.svg";
// import Spinner from "../spinner/Spinner";
// import { Link, useNavigate, useLocation } from "react-router-dom";
// import { ReactComponent as ChevronRightIcon } from "bootstrap-icons/icons/chevron-right.svg";
// import axios from "axios";
// import ApiServices from "../../api/ApiServices";
// import { toast } from "react-toastify";
// import { handleError } from "../../components/utils/AuthenticationCheck";

// const VendorDetails = () => {
//   const [vendorDetails, setVendorDetails] = useState([]);
//   const [loading, setLoading] = useState(true);

//   const navigateTo = useNavigate();
//   const location = useLocation();
//   const { vendor_id } = location.state || {};

//   useEffect(() => {
//     const fetchVendorDetails = async () => {
//       setLoading(true);
//       try {
//         const authData = JSON.parse(localStorage.getItem("auth"));
//         const token = authData?.token;
//         if (!token) {
//           throw new Error("No access token found.");
//         }

//         const response = await axios.get(ApiServices.getVendorById, {
//           headers: {
//             Authorization: `Bearer ${token}`,
//             "Content-Type": "application/json",
//           },
//           params: { vendor_id },
//           withCredentials: true,
//         });

//         const { status, message, data } = response.data;

//         if (status === 200) {
//           setVendorDetails(data || {});
//           toast.success(message, {
//             toastId: "vendor-details-fetch-successfully",
//           });
//         } else {
//           handleError({ response: { status, data: { message } } }, navigateTo);
//         }
//       } catch (error) {
//         handleError(error, navigateTo);
//       } finally {
//         setLoading(false);
//       }
//     };

//     if (vendor_id) {
//       fetchVendorDetails();
//     }
//   }, [vendor_id, navigateTo]);

//   return (
//     <div>
//       <MainLayouts>
//         <div className="relative overflow-x-auto sm:rounded-lg py-6 w-full">
//           <section
//             className="w-full pl-[3%] pr-[3%] pt-3 my-3"
//             style={{ backgroundColor: "#F7F7F7" }}
//           >
//             <div className="flex justify-between items-center bg-gray-200 py-0 px-4 rounded-lg">
//               <nav>
//                 <ol className="list-none p-0 inline-flex mb-2">
//                   <li className="flex items-center">
//                     <p className="text-blue-500 hover:text-blue-600 text-darkGreen3 pr-2">
//                       Vendor
//                     </p>
//                     <ChevronRightIcon />
//                   </li>
//                   <li className="flex items-center">
//                     <p className="text-blue-500 hover:text-blue-600 ">
//                       Details
//                     </p>
//                   </li>
//                 </ol>
//               </nav>
//               <Link
//                 to="/admin/vendor/list"
//                 // onClick={() => {
//                 //   console.log("Link clicked: Navigating to /admin/vendor/list");
//                 // }}
//                 className="text-dGrey3 bg-darkGreen3 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center"
//               >
//                 <span className="flex justify-center sm:justify-start mx-2">
//                   <ArrowLeftSquare className="mr-2" />
//                   Back
//                 </span>
//               </Link>
//             </div>

//             {loading ? (
//               <Spinner loading={loading} />
//             ) : (
//               <section className="text-gray-600 body-font">
//                 <div className="container px-1 py-1 mx-auto">
//                   <div className="flex flex-wrap -m-2">
//                     <div className="p-2 w-full">
//                       <div className="h-full flex border-grey3 border items-center p-4 rounded-lg">
//                         <div className="flex-grow hidden md:block">
//                           <h2 className="text-dash title-font font-medium">
//                             {vendorDetails.lastname && vendorDetails.firstname
//                               ? `${vendorDetails.lastname} ${vendorDetails.firstname}`
//                               : "Not Available"}
//                           </h2>
//                           <p className="text-gray-500 hidden md:block">
//                             <span className="text-gray-500">Status : </span>
//                             <span className="text-dash">
//                               {vendorDetails.status
//                                 ? vendorDetails.status
//                                 : "Not Available"}
//                             </span>
//                           </p>
//                         </div>
//                       </div>
//                     </div>

//                     <div className="px-2 w-full">
//                       <div className="h-full flex items-center border-dash border p-2 rounded-lg border-l-4">
//                         <div className="w-full pb-6">
//                           <div className="flex border-gray-200 py-2">
//                             <span className="text-gray-500">Email Address</span>
//                             <span className="ml-auto text-dash">
//                               {vendorDetails.email
//                                 ? vendorDetails.email
//                                 : "Not Available"}
//                             </span>
//                           </div>

//                           <div className="flex border-t border-grey3 py-2">
//                             <span className="text-gray-500">Phone</span>
//                             <span className="ml-auto text-dash">
//                               {vendorDetails.phone_number
//                                 ? vendorDetails.phone_number
//                                 : "Not Available"}
//                             </span>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </section>
//             )}
//           </section>
//         </div>
//       </MainLayouts>
//     </div>
//   );
// };

// export default VendorDetails;
