import { useState, useEffect } from "react";
import MainLayouts from "../../Layout/MainLayouts";
import { ReactComponent as Eye } from "bootstrap-icons/icons/eye.svg";
import { ReactComponent as ChevronRightIcon } from "bootstrap-icons/icons/chevron-right.svg";
import Spinner from "../spinner/Spinner";
import { Link, useNavigate } from "react-router-dom";
import { handleError } from "../../components/utils/AuthenticationCheck";
import { useLocation } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import CurrencyFormatter from "../../components/utils/CurrencyFormatter";
import axios from "axios";
import ApiServices from "../../api/ApiServices";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import CustomStyles from "../../components/utils/CustomStyles";
import formatDate, { formatDbDate } from "../../components/utils/DateFormatter";
import { formatTimestamp } from "../../components/utils/DateFormatter";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

const extendedSchema = yup.object().shape({
  product: yup.string().required("Product is required"),
  status: yup.string().required("Status is required"),
  start_date: yup.string().required("Start date is required"),
  end_date: yup.string().required("End date is required"),
});

function capitializeFirstLetter(letter) {
  if (typeof letter !== "string") {
    return letter;
  }
  return letter.charAt(0).toLocaleUpperCase() + letter.slice(1);
}

const OtherBillsHistory = () => {
  // const [filterText, setFilterText] = useState("");
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("");
  const [status, setStatus] = useState("");
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageData, setCurrentPageData] = useState([]);
  // const [schema, setSchema] = useState(defaultSchema);

  const navigateTo = useNavigate();
  const location = useLocation();

  const id = location?.state?.id || null;

  const [columns, setColumns] = useState([
    {
      name: "Package",
      selector: (row) => capitializeFirstLetter(row.type2),
    },
    {
      name: "Amount",
      selector: (row) => `₦ ${row.amount}`,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status2,
    },

    {
      name: "Date",
      selector: (row) => formatDbDate(row.created_at),
      width: "300px",
    },
  ]);

  //============form and calls=====================================
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(extendedSchema),
  });
  const submitData = async (data) => {
    const typeToSave = await data.product;
    setType(typeToSave);
    setStatus(await data.status);
    await fetchHistory(data);
  };

  // ==========================- fetch all WalletTransactions =================

  const fetchHistory = async (bills) => {
    try {
      setLoading(true);
      const authData = JSON.parse(localStorage.getItem("auth"));
      const token = authData?.token;
      if (!token) {
        throw new Error("No access token found.");
      }
      // console.log("i am token2", token);
      const response = await axios.post(
        ApiServices.getOtherBills,
        {
          user_id: id,
          start_date: bills.start_date,
          end_date: bills.end_date,
          product: bills.product,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },

          withCredentials: true,
        }
      );
      const { status, message, data } = response.data;
      if (Number(status) === 200) {
        if (data.length > 0) {
          const newData = data.slice();
          if (type === "airtime" || type === "data") {
            const newColumn = {
              name: "Network",
              selector: (row) =>
                capitializeFirstLetter(row.network || row.type),
            };
            addColumnIfNotExists(newColumn);
            const PhoneNumberColumn = {
              name: "Phone Number",
              selector: (row) => row.phone_number,
            };
            addColumnIfNotExists(PhoneNumberColumn);
            if (type === "data") {
              const bundleColumn = {
                name: "Bundle",
                selector: (row) => row.bundle,
              };
              addColumnIfNotExists(bundleColumn);
              removeColumns([
                "Commission",
                "Total Amount",
                "Count",
                "Denomination",
                "Customer Name",
                "Smartcard No",
                "Reference",
                "Disco",
                "Meter Number",
              ]);
            }
            if (type === "airtime") {
              removeColumns([
                "Bundle",
                "Total Amount",
                "Count",
                "Denomination",
                "Customer Name",
                "Smartcard No",
                "Commission",
                "Reference",
                "Disco",
                "Meter Number",
              ]);
            }
          } else if (type === "tv") {
            const customerColumn = {
              name: "Customer Name",
              selector: (row) => row.customerName,
            };
            const SmartCardColumn = {
              name: "Smartcard No",
              selector: (row) => row.smartcardNumber,
            };
            addColumnIfNotExists(customerColumn);
            addColumnIfNotExists(SmartCardColumn);
            removeColumns([
              "Bundle",
              "Phone Number",
              "Total Amount",
              "Count",
              "Denomination",
              "Commission",
              "Reference",
              "Disco",
              "Meter Number",
            ]);
          } else if (type === "disco") {
            const meterColumn = {
              name: "Meter Number",
              selector: (row) => row.meterNo,
            };
            const disco = {
              name: "Disco",
              selector: (row) => row.params,
            };

            const ref = {
              name: "Reference",
              selector: (row) => row.request_id,
            };
            addColumnIfNotExists(meterColumn);
            addColumnIfNotExists(disco);
            addColumnIfNotExists(ref);

            removeColumns([
              "Bundle",
              "Phone Number",
              "Total Amount",
              "Count",
              "Denomination",
              "Commission",
              "Customer Name",
              "Smartcard No",
            ]);
            //
          }
          const datatoSave = newData.map((userdata) => {
            return {
              ...userdata, // Spread original userdata object properties
              status2: status, // Add additional property 'status2'
              type2: type, // Add additional property 'type2'
            };
          });
          // console.log('data',datatoSave)
          setData(datatoSave);
        } else {
          setData(response.data.data);
          toast.error("No records found");
          console.error(response.data);
        }
      } else {
        console.log(response);
        handleError({ response: { status, data: { message } } }, navigateTo);
      }
    } catch (error) {
      console.log("error", error);
      handleError(error, navigateTo);
    } finally {
      setLoading(false);
    }
  };

  const addColumnIfNotExists = (newColumn) => {
    const columnExists = columns.some((col) => col.name === newColumn.name);
    if (!columnExists) {
      const updatedColumn = columns.splice(1, 0, newColumn);

      setColumns(updatedColumn);
    }
  };

  const removeColumns = (columnsToRemove) => {
    const updatedColumns = columns.filter(
      (column) => !columnsToRemove.includes(column.name)
    );
    setColumns(updatedColumns);
  };

  // const fetchAllTransactionList = async (data2) => {
  //   setLoading(true);
  //   try {
  //     const authData = JSON.parse(localStorage.getItem("auth"));
  //     const token = authData?.token;
  //     if (!token) {
  //       throw new Error("No access token found.");
  //     }
  //     console.log("i am token2", token);
  //     const response = await axios.post(
  //       ApiServices.getOtherBills,
  //       {
  //         user_id: id,
  //         start_date: data2.start_date,
  //         end_date: data2.end_date,
  //         product: data2.product,
  //       },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //           "Content-Type": "application/json",
  //         },

  //         withCredentials: true,
  //       }
  //     );

  //     console.log("this is all transaction", response.data);
  //     const { status, message, data } = response.data;
  //     if (status === 200) {
  //       console.log("response", response.data);
  //       console.log("response.dsta", response.data.data);
  //       setWalletTransactions(data.transactions);
  //       setTotalRows(data.total);
  //       toast.success(message, {
  //         toastId: "Physical-Bills-fetch-successful",
  //       });
  //     } else {
  //       console.log(response);
  //       handleError({ response: { status, data: { message } } }, navigateTo);
  //     }
  //   } catch (error) {
  //     console.log("error", error);
  //     handleError(error, navigateTo);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // Sort data based on creation date
  const sortedData = [...data].sort((a, b) => {
    const dateA = new Date(a.created_at);
    const dateB = new Date(b.created_at);
    return dateB - dateA;
  });
  // const exportToCSV = () => {
  //   // Define headers for your CSV
  //   const headers = Object.keys(sortedData[0]);

  //   // Reformat timestamps in the data
  //   const formattedData = sortedData.map((row) => {
  //     const newRow = { ...row }; // Create a shallow copy of the row
  //     if (newRow.created_at) {
  //       // Reformat the `created_at` field
  //       newRow.created_at = new Date(newRow.created_at).toLocaleString(
  //         "en-US",
  //         {
  //           year: "numeric",
  //           month: "2-digit",
  //           day: "2-digit",
  //           hour: "2-digit",
  //           minute: "2-digit",
  //           second: "2-digit",
  //           hour12: false, // Optional: Use 24-hour format
  //         }
  //       );
  //     }
  //     return newRow;
  //   });

  //   // Combine headers and rows
  //   const csvRows = [
  //     headers.join(","), // Add the headers
  //     ...formattedData.map(
  //       (row) =>
  //         headers
  //           .map((header) => {
  //             const value = row[header] || ""; // Get the value for each field
  //             // Wrap values containing commas or special characters in double quotes
  //             return `"${value.toString().replace(/"/g, '""')}"`;
  //           })
  //           .join(",") // Join all fields with a comma
  //     ),
  //   ];

  //   // Convert rows to CSV format
  //   const csv = csvRows.join("\n");

  //   // Create a Blob from the CSV data
  //   const csvBlob = new Blob([csv], {
  //     type: "text/csv;charset=utf-8;",
  //   });

  //   // Use FileSaver.js to trigger the download
  //   saveAs(csvBlob, "CurrentPageData.csv");
  // };

  // const exportToCSV = (currentPage) => {
  //   console.log("page to use", currentPage);
  //   const rowsPerPage = 20; // Replace this with the actual rows per page state

  //   // Calculate start and end indices for the current page
  //   const startIndex = (currentPage - 1) * rowsPerPage;
  //   const endIndex = startIndex + rowsPerPage;

  //   // Slice the data to include only the current page's data
  //   const currentPageData = sortedData.slice(startIndex, endIndex);

  //   // Define headers for your CSV
  //   const headers = Object.keys(currentPageData[0] || {});

  //   // Reformat timestamps in the data
  //   const formattedData = currentPageData.map((row) => {
  //     const newRow = { ...row }; // Create a shallow copy of the row
  //     if (newRow.created_at) {
  //       // Reformat the `created_at` field
  //       newRow.created_at = new Date(newRow.created_at).toLocaleString(
  //         "en-US",
  //         {
  //           year: "numeric",
  //           month: "2-digit",
  //           day: "2-digit",
  //           hour: "2-digit",
  //           minute: "2-digit",
  //           second: "2-digit",
  //           hour12: false, // Optional: Use 24-hour format
  //         }
  //       );
  //     }
  //     return newRow;
  //   });

  //   // Combine headers and rows
  //   const csvRows = [
  //     headers.join(","), // Add the headers
  //     ...formattedData.map(
  //       (row) =>
  //         headers
  //           .map((header) => {
  //             const value = row[header] || ""; // Get the value for each field
  //             // Wrap values containing commas or special characters in double quotes
  //             return `"${value.toString().replace(/"/g, '""')}"`;
  //           })
  //           .join(",") // Join all fields with a comma
  //     ),
  //   ];

  //   // Convert rows to CSV format
  //   const csv = csvRows.join("\n");

  //   // Create a Blob from the CSV data
  //   const csvBlob = new Blob([csv], {
  //     type: "text/csv;charset=utf-8;",
  //   });

  //   // Use FileSaver.js to trigger the download
  //   saveAs(csvBlob, "CurrentPageData.csv");
  // };

  const exportToCSV = () => {
    // Log current page for debugging
    console.log("Current page to use:", currentPage);

    const rowsPerPage = 20; // Replace this with the rows per page value

    // Calculate start and end indices for the current page
    const startIndex = (currentPage - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;

    // Slice the data to include only the current page's data
    const currentPageData = sortedData.slice(startIndex, endIndex);

    if (currentPageData.length === 0) {
      console.error("No data found for the current page.");
      return; // Stop execution if no data is found
    }

    // Define headers for your CSV
    const headers = Object.keys(currentPageData[0] || {});

    // Reformat timestamps in the data
    const formattedData = currentPageData.map((row) => {
      const newRow = { ...row }; // Create a shallow copy of the row
      if (newRow.created_at) {
        // Reformat the `created_at` field
        newRow.created_at = new Date(newRow.created_at).toLocaleString(
          "en-US",
          {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            hour12: false, // Optional: Use 24-hour format
          }
        );
      }
      return newRow;
    });

    // Combine headers and rows
    const csvRows = [
      headers.join(","), // Add the headers
      ...formattedData.map(
        (row) =>
          headers
            .map((header) => {
              const value = row[header] || ""; // Get the value for each field
              // Wrap values containing commas or special characters in double quotes
              return `"${value.toString().replace(/"/g, '""')}"`;
            })
            .join(",") // Join all fields with a comma
      ),
    ];

    // Convert rows to CSV format
    const csv = csvRows.join("\n");

    // Create a Blob from the CSV data
    const csvBlob = new Blob([csv], {
      type: "text/csv;charset=utf-8;",
    });

    // Use FileSaver.js to trigger the download
    saveAs(csvBlob, "CurrentPageData.csv");
  };

  return (
    <div>
      <MainLayouts>
        <div className="sm:rounded-lg py-6 w-full">
          {/* <h1>Customer List</h1> */}
          <section
            className="w-full pl-[3%] pr-[3%] pt-3 my-3"
            style={{ backgroundColor: "#F7F7F7" }}
          >
            <nav className="bg-gray-200 py-0 px-4 rounded-lg ">
              <ol className="list-none p-0 inline-flex mb-2">
                <li className="flex items-center">
                  <p className="text-blue-500 hover:text-blue-600 text-darkGreen3 pr-2">
                    Customer
                  </p>
                  <ChevronRightIcon />
                </li>
                <li className="flex items-center">
                  <p className="text-orange1 ">Bills</p>
                </li>
              </ol>
            </nav>
          </section>
          <div className="pb-4 bg-white dark:bg-gray-900 flex flex-wrap items-center justify-between gap-4">
            {/* Search Input Field */}
            <form className="flex flex-wrap items-center justify-between gap-3 ">
              {/* <div className="relative w-full md:w-auto flex-grow">
              <input
                type="search"
                className="block w-full px-3 py-2 text-md text-gray-900 border border-gray-300 rounded-lg bg-gray-50 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                placeholder="Search"
                value={filterText}
                onChange={handleFilter}
              />
            </div> */}

              <div className="flex flex-col">
                <label htmlFor="package" className="text-sm pb-2">
                  Package
                </label>
                <select
                  className={`h-[50px] bg-white outline-none ${
                    errors.product ? "border-[1px] border-red1" : "border-[0px]"
                  } rounded-sm py-[10px] px-[16px] text-sm `}
                  id="package"
                  {...register("product")}
                  onChange={(e) => setType(e.target.value)}
                >
                  <option value="" selected>
                    Choose Package
                  </option>
                  <option value="airtime">Airtime</option>
                  <option value="data">Data</option>
                  <option value="disco">Electricity</option>
                  <option value="tv">Tv</option>
                  {/* <option value="physical">Physical</option> */}
                </select>
              </div>

              <div className="flex flex-col ">
                <label htmlFor="status" className="text-sm pb-2 ">
                  Status
                </label>
                <select
                  className={`h-[50px] bg-white outline-none ${
                    errors.status ? "border-[1px] border-red1" : "border-[0px]"
                  } rounded-sm py-[10px] px-[16px] text-sm `}
                  id="status"
                  {...register("status")}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <option value="" selected>
                    Choose Status
                  </option>
                  <option value="success">successful</option>
                  <option value="failed">failed</option>
                  <option value="pending">pending</option>
                </select>
                {/* {errors.status && (
                <p className="text-xs text-red1">{errors.status?.message}</p>
              )} */}
              </div>

              <div className="flex flex-col">
                <label htmlFor="start_date" className="text-sm pb-2 ">
                  Start date
                </label>
                <input
                  className={`h-[50px] bg-white outline-none ${
                    errors.start_date
                      ? "border-[1px] border-red1"
                      : "border-[0px]"
                  } rounded-sm py-[10px] px-[16px] text-sm text-black  `}
                  type="date"
                  id="start_date"
                  placeholder="Start Date"
                  {...register("start_date")}
                />
                {/* {errors.start_date && (
              <p className="text-xs text-red1">{errors.start_date?.message}</p>
            )} */}
              </div>
              <div className="flex flex-col ">
                <label htmlFor="end_date" className="text-sm pb-2">
                  End date
                </label>
                <input
                  className={`h-[50px] bg-white outline-none ${
                    errors.start_date
                      ? "border-[1px] border-red1"
                      : "border-[0px]"
                  } rounded-sm py-[10px] px-[16px] text-sm text-black `}
                  type="date"
                  id="end_date"
                  {...register("end_date")}
                />
                {/* {errors.end_date && (
              <p className="text-xs text-red1">{errors.end_date?.message}</p>
            )} */}
              </div>

              <div className="">
                {loading ? (
                  <button
                    className="bg-darkGreen1 inline-flex w-full justify-center gap-x-1.5 rounded-md px-3 py-3 text-sm font-semibold text-lGrey3 mt-4"
                    // onClick={handleSubmit(submitData)}
                  >
                    Loading...
                  </button>
                ) : (
                  <button
                    className="bg-darkGreen1 inline-flex w-full justify-center gap-x-1.5 rounded-md px-3 py-3 text-sm font-semibold text-lGrey3 mt-4"
                    onClick={handleSubmit(submitData)}
                  >
                    Search
                  </button>
                )}
              </div>
            </form>

            <div className="relative w-full md:w-auto flex-shrink-0">
              <div>
                <button
                  className="bg-orange1 inline-flex w-full justify-center gap-x-1.5 rounded-md px-3 py-3 text-sm font-semibold text-lGrey3"
                  onClick={() => {
                    exportToCSV(currentPage);
                  }}
                >
                  Download page
                </button>
              </div>
            </div>
          </div>
          {loading ? (
            <Spinner loading={loading} />
          ) : (
            <DataTable
              columns={columns}
              progressPending={loading}
              data={sortedData}
              pagination
              paginationPerPage={20}
              onChangePage={(page) => {
                setCurrentPage(page);
                console.log("page", page);
              }}
              highlightOnHover
              noDataComponent={<div>No customer found</div>}
              customStyles={CustomStyles}
            />
          )}
        </div>
      </MainLayouts>
    </div>
  );
};

export default OtherBillsHistory;
