import { useState, useEffect } from "react";
import MainLayouts from "../../Layout/MainLayouts";
import { ReactComponent as Eye } from "bootstrap-icons/icons/eye.svg";
import { ReactComponent as ChevronRightIcon } from "bootstrap-icons/icons/chevron-right.svg";
import Spinner from "../spinner/Spinner";
import { Link, useNavigate } from "react-router-dom";
import { handleError } from "../../components/utils/AuthenticationCheck";
import { useLocation } from "react-router-dom";
import CurrencyFormatter from "../../components/utils/CurrencyFormatter";
import { formatDbDate } from "../../components/utils/DateFormatter";

import axios from "axios";
import ApiServices from "../../api/ApiServices";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import CustomStyles from "../../components/utils/CustomStyles";
import formatDate from "../../components/utils/DateFormatter";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

const WalletHistory = () => {
  const [WalletTransactions, setWalletTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filterText, setFilterText] = useState("");
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const [totalRows, setTotalRows] = useState(0);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const navigateTo = useNavigate();
  const location = useLocation();

  const id = location?.state?.id || null;

  // ==========================- fetch all WalletTransactions =================
  useEffect(() => {
    const fetchAllTransactionList = async () => {
      setLoading(true);
      try {
        const authData = JSON.parse(localStorage.getItem("auth"));
        const token = authData?.token;
        if (!token) {
          throw new Error("No access token found.");
        }
        // console.log("i am token2", token);
        const response = await axios.post(
          ApiServices.getWalletTransactions,
          { user_id: id, page: page, per_page: perPage },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },

            withCredentials: true,
          }
        );
        // console.log(page, perPage);
        // console.log("this is all transaction", response.data);
        const { status, message, data } = response.data;
        if (status === 200) {
          // console.log("response", response.data);
          // console.log("response.dsta", response.data.data);
          setWalletTransactions(data.transactions);
          setTotalRows(data.total);
          toast.success(message, { toastId: "Wallet-fetch-successful" });
        } else {
          console.log(response);
          handleError({ response: { status, data: { message } } }, navigateTo);
        }
      } catch (error) {
        console.log("error", error);
        handleError(error, navigateTo);
      } finally {
        setLoading(false);
      }
    };

    fetchAllTransactionList();
  }, [page, perPage]);
  // ================================== end of list of customer ==========================================

  //filter data by name
  const handleFilter = (e) => {
    setFilterText(e.target.value);
    // setResetPaginationToggle(!resetPaginationToggle);
  };

  //handle row change

  const handleRowChange = (newPerPage) => {
    // console.log("changing row too");
    setPerPage(newPerPage);

    setPage(page);
  };

  //handle Page Change
  const handlePageChange = (page) => {
    // console.log("handling page change");
    setPage(page);
  };

  const columns = [
    // {
    //   name: "Details",
    //   cell: (row) => (
    //     <div className="d-flex justify-content-end ">
    //       <Link
    //         to="/admin/customer/details"
    //         state={{ id: row.id }}
    //         // className=""
    //         onClick={(e) => {
    //           navigateTo("/admin/customer/details", {
    //             replace: true,
    //             state: { id: row.id },
    //           });
    //         }}
    //       >
    //         <Eye className="w-5 h-5 text-dash4 " />
    //       </Link>
    //     </div>
    //   ),
    //   width: "80px",
    // },

    {
      name: "Description",
      sortable: true,
      width: "120px",
      cell: (row) => (
        <div>
          <span className="block font-bold">
            {row.description ? row.description.toUpperCase() : "NOT AVAILABLE"}{" "}
          </span>
        </div>
      ),
    },

    {
      name: "Type",
      selector: (row) =>
        row.type === "Debit" ? (
          <span className="block font-bold text-red1">Debit</span>
        ) : (
          <span className="block font-bold text-darkGreen2">Credit</span>
        ),
      sortable: true,
      width: "120px",
    },

    {
      name: "Amount",
      selector: (row) => CurrencyFormatter(Number(row.amount)),
      sortable: true,
      width: "120px",
    },

    {
      name: "Balance Before",
      selector: (row) => CurrencyFormatter(Number(row.balance_before)),
      sortable: true,
      width: "150px",
    },

    {
      name: "Balance After",
      selector: (row) => CurrencyFormatter(Number(row.balance_after)),
      sortable: true,
      width: "150px",
    },
    {
      name: "Reference",
      selector: (row) => row.reference,
      sortable: true,
      width: "300px",
    },

    {
      name: "Date",
      selector: (row) => formatDbDate(row.created_at),
      sortable: true,
    },
  ];

  //filter data
  const filteredItems = WalletTransactions.filter((item) => {
    const matchesFilterText = Object.values(item).some(
      (value) =>
        typeof value === "string" &&
        value.toLowerCase().includes(filterText.toLowerCase())
    );

    return matchesFilterText;
  });

  const data = filteredItems.map((transaction) => ({
    created_at: transaction.created_at,
    description: transaction.description
      ? transaction.description.toUpperCase()
      : "NOT AVAILABLE",

    reference: transaction.reference ? transaction.reference : "NOT AVAILABLE",
    type: transaction.type ? String(transaction.type) : "NOT AVAILABLE",

    amount: transaction.amount ? transaction.amount : "NOT AVAILABLE",
    balance_before: transaction.balance_before
      ? transaction.balance_before
      : "NOT AVAILABLE",
    balance_after: transaction.balance_after
      ? transaction.balance_after
      : "NOT AVAILABLE",
  }));

  // Sort data based on creation date
  const sortedData = [...data].sort((a, b) => {
    const dateA = new Date(a.created_at);
    const dateB = new Date(b.created_at);
    return dateB - dateA;
  });
  const exportToCSV = () => {
    // Define headers for your CSV
    const headers = Object.keys(sortedData[0]);

    // Reformat timestamps in the data
    const formattedData = sortedData.map((row) => {
      const newRow = { ...row }; // Create a shallow copy of the row
      if (newRow.created_at) {
        // Reformat the `created_at` field
        newRow.created_at = new Date(newRow.created_at).toLocaleString(
          "en-US",
          {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            hour12: false, // Optional: Use 24-hour format
          }
        );
      }
      return newRow;
    });

    // Combine headers and rows
    const csvRows = [
      headers.join(","), // Add the headers
      ...formattedData.map(
        (row) =>
          headers
            .map((header) => {
              const value = row[header] || ""; // Get the value for each field
              // Wrap values containing commas or special characters in double quotes
              return `"${value.toString().replace(/"/g, '""')}"`;
            })
            .join(",") // Join all fields with a comma
      ),
    ];

    // Convert rows to CSV format
    const csv = csvRows.join("\n");

    // Create a Blob from the CSV data
    const csvBlob = new Blob([csv], {
      type: "text/csv;charset=utf-8;",
    });

    // Use FileSaver.js to trigger the download
    saveAs(csvBlob, "CurrentPageData.csv");
  };

  return (
    <div>
      <MainLayouts>
        <div className="sm:rounded-lg py-6 w-full">
          {/* <h1>Customer List</h1> */}
          <section
            className="w-full pl-[3%] pr-[3%] pt-3 my-3"
            style={{ backgroundColor: "#F7F7F7" }}
          >
            <nav className="bg-gray-200 py-0 px-4 rounded-lg ">
              <ol className="list-none p-0 inline-flex mb-2">
                <li className="flex items-center">
                  <p className="text-blue-500 hover:text-blue-600 text-darkGreen3 pr-2">
                    Customer
                  </p>
                  <ChevronRightIcon />
                </li>
                <li className="flex items-center">
                  <p className="text-orange1 ">Wallet Transactions</p>
                </li>
              </ol>
            </nav>
          </section>
          <div className="pb-4 bg-white dark:bg-gray-900 flex flex-wrap items-center justify-between gap-4">
            {/* Search Input Field */}
            <div className="relative w-full md:w-auto flex-grow">
              <input
                type="search"
                className="block w-full px-3 py-2 text-md text-gray-900 border border-gray-300 rounded-lg bg-gray-50 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                placeholder="Search"
                value={filterText}
                onChange={handleFilter}
              />
            </div>

            <div className="relative w-full md:w-auto flex-shrink-0">
              <div>
                <button
                  className="bg-orange1 inline-flex w-full justify-center gap-x-1.5 rounded-md px-3 py-3 text-sm font-semibold text-lGrey3"
                  onClick={exportToCSV}
                >
                  Download page
                </button>
              </div>
            </div>
          </div>
          {loading ? (
            <Spinner loading={loading} />
          ) : (
            <DataTable
              columns={columns}
              progressPending={loading}
              data={sortedData}
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              paginationPerPage={perPage}
              paginationDefaultPage={page}
              paginationRowsPerPageOptions={[20]}
              onChangeRowsPerPage={handleRowChange}
              onChangePage={handlePageChange}
              highlightOnHover
              noDataComponent={<div>No customer found</div>}
              customStyles={CustomStyles}
            />
          )}
        </div>
      </MainLayouts>
    </div>
  );
};

export default WalletHistory;
