// import React, { useState, useEffect } from "react";
// import MainLayouts from "../../Layout/MainLayouts";
// import { ReactComponent as List } from "bootstrap-icons/icons/list.svg";
// import { ReactComponent as CloudUploadFill } from "bootstrap-icons/icons/cloud-upload-fill.svg";
// import { ReactComponent as ChevronRightIcon } from "bootstrap-icons/icons/chevron-right.svg";
// import { ReactComponent as XSquareFill } from "bootstrap-icons/icons/x-square-fill.svg";
// import axios from "axios";
// import ApiServices from "../../api/ApiServices";
// import { toast } from "react-toastify";
// import { Link, useNavigate, useLocation } from "react-router-dom";
// import { handleError } from "../../components/utils/AuthenticationCheck";

// const AddSlider = () => {
//     const [images, setImages] = useState([]);
//     const [loading, setLoading] = useState(false);
//     const navigate = useNavigate();

//     const validateForm = () => {
//         if (!images.length) {
//             toast.error("At least one image is required.");
//             return false;
//         }

//         return true;
//     };




//     const convertToBase64 = (file) => {
//         return new Promise((resolve, reject) => {
//             const reader = new FileReader();
//             reader.readAsDataURL(file);
//             reader.onload = () => resolve(reader.result);
//             reader.onerror = (error) => reject(error);
//         });
//     };

//     const validateFile = (file) => {
//         if (!file.type.startsWith("image/")) {
//             toast.info("Only image files are allowed.");
//             return false;
//         }
//         if (file.size > 5 * 1024 * 1024) {
//             toast.info("File size exceeds 5MB limit.");
//             return false;
//         }
//         return true;
//     };

//     const handleFileChange = async (e) => {
//         const filesArray = Array.from(e.target.files);
//         if (filesArray.length + images.length > 6) {
//             toast.error("You can only upload a maximum of 6 images.");
//             return;
//         }
//         const base64Images = [];

//         for (const file of filesArray) {
//             if (!validateFile(file)) continue;
//             try {
//                 const base64 = await convertToBase64(file);
//                 base64Images.push(base64);
//             } catch {
//                 toast.error("Failed to convert image.");
//             }
//         }
//         setImages((prevImages) => [...prevImages, ...base64Images]);
//     }, [images]);

//     const handleImageRemove = (index) => {
//         setImages((prevImages) => prevImages.filter((_, i) => i !== index));
//     };

//     const renderImagePreviews = () => {
//         return images.map((image, index) => (
//             <div key={index} className="relative w-20 h-20">
//                 <img src={image} alt={`Preview ${index}`} className="w-full h-full object-cover rounded" />
//                 <button
//                     onClick={() => handleImageRemove(index)}
//                     className="absolute top-0 right-0 bg-red-500 text-white rounded-full p-1 hover:bg-red-600"
//                     title="Remove image"
//                 >
//                     <XSquareFill className="w-5 h-5 text-red1" />
//                     {/* &times; */}
//                 </button>
//             </div>
//         ));
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         if (!validateForm()) return;

//         setLoading(true);
//         const formData = {
//             images: images.map((image) => image.split(",")[1]),
//         };

//         try {
//             const authData = JSON.parse(localStorage.getItem("auth"));
//             const token = authData?.token;
//             if (!token) throw new Error("No access token found.");

//             const response = await axios.post(ApiServices.AddSlider, formData, {
//                 headers: {
//                     Authorization: `Bearer ${token}`,
//                     "Content-Type": "application/json",
//                 },
//                 withCredentials: true,
//             });

//             const { status, message } = response.data;
//             if (status === 200) {
//                 toast.success(message);
//                 setImages([]);
//                 navigate("/admin/slider/list");
//             } else {
//                 handleError({ response: { status, data: { message } } }, navigate);
//             }
//         } catch (error) {
//             handleError(error, navigate);
//         } finally {
//             setLoading(false);
//         }
//     };

//     return (
//         <MainLayouts>
//             <div className="relative overflow-x-auto sm:rounded-lg py-6 w-full">
//                 <section className="w-full pr-[3%] pt-3 my-3" style={{ backgroundColor: "#F7F7F7" }}>
//                     <nav className="bg-gray-200 py-0 px-4 rounded-lg">
//                         <ol className="list-none p-0 inline-flex mb-2">
//                             <li className="flex items-center">
//                                 <p className="text-blue-500 hover:text-blue-600 text-darkGreen3 pr-2">Add Product Stock</p>
//                                 <ChevronRightIcon />
//                             </li>
//                             <li className="flex items-center">
//                                 <p className="text-orange1">Images</p>
//                             </li>
//                         </ol>
//                     </nav>
//                 </section>

//                 <section className="gray_background">
//                     <div className="container px-1 pb-2 mx-auto">
//                         <div className="lg:w-full md:w-full bg-white rounded-lg py-3 px-2 flex flex-col md:ml-auto w-full mt-10 md:mt-0 relative z-10 shadow-md bg-dGrey3">
//                             <form onSubmit={handleSubmit}>
//                                 <div className="flex flex-col md:flex-row gap-6 mt-4">
//                                     <div className="flex-1">
//                                         <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Name</label>
//                                         <div className="bg-gray-50 border border-dGrey1 text-gray-900 text-sm rounded-lg block w-full p-2.5">{name}</div>
//                                     </div>
//                                     <div className="flex-1">
//                                         <label htmlFor="productCategory" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Product Category Name</label>
//                                         <div className="bg-gray-50 border border-dGrey1 text-gray-900 text-sm rounded-lg block w-full p-2.5">{productCategory}</div>
//                                     </div>
//                                 </div>

//                                 <div className="mt-6">
//                                     {loading && (
//                                         <div className="mb-4 text-sm text-orange1" aria-live="polite">
//                                             Loading, please wait...
//                                         </div>
//                                     )}
//                                     <div className="flex flex-col my-4">
//                                         {images.length > 0 && <div className="flex space-x-2">{renderImagePreviews()}</div>}
//                                     </div>
//                                     <div className="flex items-center justify-center w-full">
//                                         <label
//                                             htmlFor="slider"
//                                             className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-orange1 rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-gray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
//                                         >
//                                             <div className="flex flex-col items-center justify-center pt-5 pb-6">
//                                                 <CloudUploadFill className="mr-2 w-8 h-8 text-orange1" />
//                                                 <p className="mb-2 text-sm text-darkGreen3">
//                                                     <span className="font-semibold">Click to upload</span> or drag and drop
//                                                 </p>
//                                             </div>
//                                             <input
//                                                 id="images"
//                                                 type="file"
//                                                 accept="image/*"
//                                                 multiple
//                                                 onChange={handleFileChange}
//                                                 className="hidden"
//                                             />
//                                         </label>
//                                     </div>
//                                 </div>

//                                 <div className="flex flex-col gap-4 md:flex-row md:gap-4 md:justify-end mt-4">
//                                     <Link
//                                         to="/admin/slider/list"
//                                         className="text-dGrey3 py-3 bg-black1 hover:bg-blue-800 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 text-center flex items-center"
//                                     >
//                                         <List className="mr-2" />
//                                         Back To List
//                                     </Link>
//                                     <button
//                                         type="submit"
//                                         className={`text-white bg-darkGreen3 text-dGrey3 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded font-medium ${loading ? "btn-loading" : "btn-primary"}`}
//                                         disabled={loading}
//                                     >
//                                         {loading ? "Loading..." : "Add To Product Stock"}
//                                     </button>
//                                 </div>
//                             </form>
//                         </div>
//                     </div>
//                 </section>
//             </div>
//         </MainLayouts>
//     );
// };

// export default AddSlider;


import React, { useState } from "react";
import MainLayouts from "../../Layout/MainLayouts";
import { ReactComponent as List } from "bootstrap-icons/icons/list.svg";
import { ReactComponent as CloudUploadFill } from "bootstrap-icons/icons/cloud-upload-fill.svg";
import { ReactComponent as ChevronRightIcon } from "bootstrap-icons/icons/chevron-right.svg";
import { ReactComponent as XSquareFill } from "bootstrap-icons/icons/x-square-fill.svg";
import axios from "axios";
import ApiServices from "../../api/ApiServices";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { handleError } from "../../components/utils/AuthenticationCheck";

const AddSlider = () => {
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const validateForm = () => {
        if (images.length === 0) {
            toast.error("At least one image is required.");
            return false;
        }
        return true;
    };

    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };

    const validateFile = (file) => {
        if (!file.type.startsWith("image/")) {
            toast.info("Only image files are allowed.");
            return false;
        }
        if (file.size > 1 * 1024 * 1024) {
            toast.info("File size exceeds 1MB limit.");
            return false;
        }

        return true;
    };

    const handleFileChange = async (e) => {
        const filesArray = Array.from(e.target.files);
        if (filesArray.length + images.length > 6) {
            toast.error("You can only upload a maximum of 6 images.");
            return;
        }

        const base64Images = [];
        for (const file of filesArray) {
            if (!validateFile(file)) continue;
            try {
                const base64 = await convertToBase64(file);
                base64Images.push(base64);
            } catch {
                toast.error("Failed to convert image.");
            }
        }
        setImages((prevImages) => [...prevImages, ...base64Images]);
    };

    const handleImageRemove = (index) => {
        setImages((prevImages) => prevImages.filter((_, i) => i !== index));
    };

    const renderImagePreviews = () => (
        images.map((image, index) => (
            <div key={index} className="relative w-20 h-20">
                <img src={image} alt={`Preview ${index}`} className="w-full h-full object-cover rounded" />
                <button
                    onClick={() => handleImageRemove(index)}
                    className="absolute top-0 right-0 bg-red-500 text-white rounded-full p-1 hover:bg-red-600"
                    title="Remove image"
                >
                    <XSquareFill className="w-5 h-5 text-red1" />
                </button>
            </div>
        ))
    );

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;

        setLoading(true);
        const formData = {
            // slider: images.map((image) => image.split(",")[1]),
            // slider: images.map((image) => image.split(",")[1]).join(','),
            slider: images.length > 0 ? images[0].split(",")[1] : "",
        };
        console.log("image uplaod for home slider", formData);

        try {
            const authData = JSON.parse(localStorage.getItem("auth"));
            const token = authData?.token;
            if (!token) throw new Error("No access token found.");

            const response = await axios.post(ApiServices.AddSlider, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
                withCredentials: true,
            });

            const { status, message } = response.data;
            if (status === 200) {
                toast.success(message);
                setImages([]);
                navigate("/admin/slider/list");
            } else {
                handleError({ response: { status, data: { message } } }, navigate);
            }
        } catch (error) {
            handleError(error, navigate);
        } finally {
            setLoading(false);
        }
    };

    return (
        <MainLayouts>
            <div className="relative overflow-x-auto sm:rounded-lg py-6 w-full">
                <section className="w-full pr-[3%] pt-3 my-3" style={{ backgroundColor: "#F7F7F7" }}>
                    <nav className="bg-gray-200 py-0 px-4 rounded-lg">
                        <ol className="list-none p-0 inline-flex mb-2">
                            <li className="flex items-center">
                                <p className="text-blue-500 hover:text-blue-600 text-darkGreen3 pr-2">Image Slider</p>
                                <ChevronRightIcon />
                            </li>
                            <li className="flex items-center">
                                <p className="text-orange1">Upload</p>
                            </li>
                        </ol>
                    </nav>
                </section>

                <section className="gray_background">
                    <div className="container px-1 pb-2 mx-auto">
                        <div className="lg:w-full md:w-full bg-white rounded-lg py-3 px-2 flex flex-col md:ml-auto w-full mt-10 md:mt-0 relative z-10 shadow-md bg-dGrey3">
                            <form onSubmit={handleSubmit}>

                                <div className="mt-6">
                                    {loading && (
                                        <div className="mb-4 text-sm text-orange1" aria-live="polite">
                                            Loading, please wait...
                                        </div>
                                    )}
                                    <div className="flex flex-col my-4">
                                        {images.length > 0 && <div className="flex space-x-2">{renderImagePreviews()}</div>}
                                    </div>
                                    <div className="flex items-center justify-center w-full">
                                        <label
                                            htmlFor="images"
                                            className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-orange1 rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-gray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                                        >
                                            <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                                <CloudUploadFill className="mr-2 w-8 h-8 text-orange1" />
                                                <p className="mb-2 text-sm text-darkGreen3">
                                                    <span className="font-semibold">Click to upload</span> or drag and drop
                                                </p>
                                            </div>
                                            <input
                                                id="images"
                                                type="file"
                                                accept="image/*"
                                                multiple
                                                onChange={handleFileChange}
                                                className="hidden"
                                            />
                                        </label>
                                    </div>
                                </div>

                                <div className="flex flex-col gap-4 md:flex-row md:gap-4 md:justify-end mt-4">
                                    <Link
                                        to="/admin/slider/list"
                                        className="text-dGrey3 py-3 bg-black1 hover:bg-blue-800 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 text-center flex items-center"
                                    >
                                        <List className="mr-2" />
                                        Back To List
                                    </Link>
                                    <button
                                        type="submit"
                                        className={` bg-darkGreen3 text-dGrey3 focus:outline-none hover:bg-blue-700  border border-gray-300 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                                        disabled={loading}
                                    >
                                        Add Image
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </div>
        </MainLayouts>
    );
};

export default AddSlider;


