import React, { useEffect, useState, useContext } from "react";
import Navbar from "../Layout/Navbar";
import Footer from "../Layout/Footer";
import { ReactComponent as ChevronDownIcon } from "bootstrap-icons/icons/chevron-down.svg";
import { ReactComponent as ChevronRightIcon } from "bootstrap-icons/icons/chevron-right.svg";
import { Link, useLocation } from "react-router-dom";
import LogoutButton from "../components/utils/LogoutButton";
// import PrivateRoute from "../AuthProvider/PrivateRoute";
import { AuthContext } from "../AuthProvider/AuthProvider";

const SidebarSection = ({ title, links, isOpen, onToggle }) => {
  const location = useLocation();


  return (
    <div className="rounded-lg">
      <button
        className="flex justify-between w-full px-4 py-2 text-left bg-gray-200 rounded-t-lg focus:outline-none"
        onClick={onToggle}
      >
        <span className="text-black2">{title}</span>
        <ChevronDownIcon
          className={`transform transition-transform ${isOpen ? "rotate-180" : "rotate-0"
            }`}
        />
      </button>
      {isOpen && (
        <div className="p-4 bg-white rounded-b-lg">
          <ul>
            {links.map(({ to, label }, index) => (
              <li key={`${to}-${index}`}>
                {/* <PrivateRoute> */}
                <Link
                  className={`${location.pathname === to
                    ? "text-darkGreen3 font-bold" // active link style
                    : "text-grey2 hover:text-dash5"
                    } inline-flex items-center w-full mt-1 text-base transition duration-500 ease-in-out transform rounded-lg focus:shadow-outline`}
                  to={to}
                >
                  <span>{label}</span>
                </Link>
                {/* </PrivateRoute> */}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

const MainLayouts = ({ children }) => {
  const { permissions } = useContext(AuthContext);

  const [openSections, setOpenSections] = useState({
    product: false,
    orders: false,
    settings: false,
    adminManagement: false,
    billsManagement: false,
    accountSettings: false,
    customerManagement: false,
    ridersManagement: false,
  });

  // Function to check if user has a specific permission by name
  const hasPermission = (requiredPermission) => {
    return permissions.some(permission => permission?.name === requiredPermission);
  };

  // Assign permission to variable to make condition on sidebar
  const canViewCustomer = hasPermission('customer.view');
  const canViewProduct = hasPermission('product.view');
  const canViewOrders = hasPermission('order.view');
  const canViewAdmins = hasPermission('admin.view');
  const canViewPermissions = hasPermission('permission.view');
  const canViewRoles = hasPermission('role.view');
  const canViewVendors = hasPermission('vendor.view');
  const canViewActivityLogs = hasPermission('admin.activities');
  //bills
  const canViewBills = hasPermission('bill.view');
  //category
  const canViewCategories = hasPermission('category.view');
  //tickets
  const canViewTickets = hasPermission('ticket.view');
  //vendor
  const canViewStore = hasPermission('vendor.store');
  //store
  const canViewProperty = hasPermission('property.view');
  //switcher
  const canViewSwitcher = hasPermission('switcher.view');
  // app setting
  const canViewAppSetting = hasPermission('settings.view');
  //slider  
  const canViewSlider = hasPermission('settings.view');
  //slider  
  const canViewRiders = hasPermission('rider.view');
  const canEditRiders = hasPermission('rider.edit');

  //need to hide the entire section if user does not have 
  const canViewAdminSection = canViewAdmins || canViewPermissions || canViewRoles || canViewVendors || canViewActivityLogs;
  const canViewProductSection = canViewVendors || canViewStore || canViewProperty;
  const canViewbillsSection = canViewSwitcher || canViewBills;
  const canViewRidersSection = canViewRiders || canEditRiders;


  // const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  // const [setIsSidebarOpen] = useState(true);
  const location = useLocation()
  const IsDashBoard = location.pathname === '/admin/dashboard'

  // Mapping pathnames to breadcrumb titles
  const pathToNameMap = {
    "/admin/dashboard": "Dashboard",
    "/admin/customer": "Customers",
    "/admin/products": "Vendors Product",
    "/admin/products/stock/list": "Stock Product",
    "/admin/store/list": "Vendor Store",
    "/admin/product/property/list": "Property",
    "/admin/order/list": "Orders",
    "/admin/return/items": "Return",
    "/admin/view/list": "Administrators",
    "/admin/permission/list": "Permissions",
    "/admin/roles/list": "Roles",
    "/admin/vendor/list": "Vendor",
    "/admin/activity/log": "Administrator Activity Log",
    "/admin/product/list": "Airtime Products",
    "/admin/billers/list": "Billers",
    "/admin/switchers/list": "Switchers",
    "/admin/product/all/list": "Bills Products",
    "/admin/bills/search": "Search Bills",
    "/admin/category/list": "Product Categories",
    "/admin/help/list": "Help Center",
    "/admin/account/2fa/update/status": "2FA Status",
    "/admin/account/notification/status": "Notification",
    "/admin/password/change": "Change Password",
    "/admin/appsettings/list": "App Settings",
    "/admin/slider/list": "Slider",
    "/admin/riders/list": "Riders",

  };

  const currentPageName = pathToNameMap[location.pathname] || "Dashboard";


  const toggleSection = (section) => {
    setOpenSections((prev) => ({ ...prev, [section]: !prev[section] }));
  };

  // const toggleSidebar = () => {
  //   setIsSidebarOpen((prev) => !prev);
  // };

  // useEffect(() => {
  //   console.log('Current User Permissions:', permissions);
  // }, [permissions]);


  // console.log({ canViewBills, canViewSwitcher, canViewbillsSection });





  return (
    <>
      <Navbar />
      {/* sidebar here */}
      <div
        className="w-full pl-[3%] pr-[3%] pt-3 mt-25"
        style={{
          backgroundColor: "#F7F7F7",
        }}
      >
        <nav className="bg-gray-200 py-0 px-4 rounded-lg">
          <ol className="list-none p-0 inline-flex">
            <li className="flex items-center">
              <p className="text-blue-500 hover:text-blue-600 text-darkGreen3 pr-2">
                Admin
              </p>
              <ChevronRightIcon />
            </li>
            <li className="flex items-center">
              {/* <p className="text-orange1">Dashboard</p> */}
              <p className="text-orange1">{currentPageName}</p>
            </li>
          </ol>
        </nav>
      </div>

      <div
        className="flex overflow-hidden rounded-lg pl-[3%] pr-[3%]"
        style={{ backgroundColor: "#F7F7F7" }}
      >
        <div className="hidden md:flex md:flex-shrink-0 rounded-2xl border border-grey3 my-4 h-[600px] mb-8">
          <div className="flex flex-col w-64">
            <div className="flex flex-col flex-grow pt-5 overflow-y-auto">
              <div className="flex flex-col flex-grow px-4 mt-2">
                <aside className="flex-1 space-y-1">
                  <Link to="/admin/dashboard" className="block p-2 text-xl font-medium tracking-tighter text-darkGreen3 transition duration-500 ease-in-out transform cursor-pointer hover:text-gray-900 mx-auto mb-3">
                    Dashboard
                  </Link>
                  {canViewCustomer && (
                    <>
                      <hr className="border border-lGrey" />
                      <SidebarSection
                        title="Customer"
                        links={[{ to: "/admin/customer", label: "Customers" }]}
                        isOpen={openSections.customerManagement}
                        onToggle={() => toggleSection("customerManagement")}
                      />
                    </>
                  )}

                  {canViewProductSection && (
                    <>
                      <hr className="border border-lGrey" />
                      <SidebarSection
                        title="Product"
                        links={[
                          canViewVendors ? { to: "/admin/products", label: "Vendors Product" } : null,
                          canViewProduct ? {
                            to: "/admin/products/stock/list",
                            label: "Stock Product",
                          } : null,
                          canViewStore ? { to: "/admin/store/list", label: "Vendor Store" } : null,
                          canViewProperty ? {
                            to: "/admin/product/property/list",
                            label: "Property",
                          } : null,

                        ].filter(Boolean)}
                        isOpen={openSections.product}
                        onToggle={() => toggleSection("product")}
                      />
                    </>
                  )}
                  {canViewOrders && (
                    <>
                      <hr className="border border-lGrey" />
                      <SidebarSection
                        title="Orders "
                        links={[
                          { to: "/admin/order/list", label: "Orders" },
                          // { to: "/admin/return/items", label: "Return" },
                          // { to: "/admin/order/fulfiled", label: "Fulfilled" },
                          // { to: "/admin/help/pending", label: "Help Pending" },
                          // { to: "/admin/help/center", label: "Help Center" },
                        ].filter(Boolean)}
                        isOpen={openSections.orders}
                        onToggle={() => toggleSection("orders")}
                      />
                    </>
                  )}

                  {canViewAdminSection && (
                    <>
                      <hr className="border border-lGrey" />
                      <SidebarSection
                        title="Admin "
                        links={[
                          canViewAdmins ? {
                            to: "/admin/view/list",
                            label: "Admins",
                          } : null,
                          canViewPermissions ? {
                            to: "/admin/permission/list",
                            label: "Permissions",
                          } : null,
                          canViewRoles ? { to: "/admin/roles/list", label: "Roles" } : null,

                          canViewVendors ? {
                            to: "/admin/vendor/list",
                            label: "Vendor",
                          } : null,

                          canViewActivityLogs ? { to: "/admin/activity/log", label: "Activity" } : null,
                        ].filter(Boolean)}
                        isOpen={openSections.adminManagement}
                        onToggle={() => toggleSection("adminManagement")}
                      />
                    </>
                  )}
                  {canViewbillsSection &&
                    <>
                      <hr className="border border-lGrey" />
                      <SidebarSection
                        title="Bills "
                        links={[
                          canViewBills ? {
                            to: "/admin/product/list",
                            label: "Airtime",
                          } : null,
                          canViewBills ? {
                            to: "/admin/billers/list",
                            label: "Billers",
                          } : null,
                          canViewSwitcher ? {
                            to: "/admin/switchers/list",
                            label: "Switchers",
                          } : null,
                          canViewBills ? {
                            to: "/admin/product/all/list",
                            label: "Products",
                          } : null,
                          canViewBills ? {
                            to: "/admin/bills/search",
                            label: "Search Bills ",
                          } : null,

                        ].filter(Boolean)}
                        isOpen={openSections.billsManagement}
                        onToggle={() => toggleSection("billsManagement")}
                      />
                    </>
                  }

                  {/* {canViewRiders && (
                    <>
                      <hr className="border border-lGrey" />
                      <SidebarSection
                        title="Riders"
                        links={[
                          canViewRiders ? { to: "/admin/riders/list", label: "Riders" } : null,
                        ].filter(Boolean)}
                        isOpen={openSections.ridersManagement}
                        onToggle={() => toggleSection("ridersManagement")}
                      />
                    </>
                       } */}

                  {canViewRiders && (
                    <>
                      <hr className="border border-lGrey" />
                      <SidebarSection
                        title="Riders"
                        links={[
                          { to: "/admin/riders/list", label: "Riders" }
                        ]}
                        isOpen={openSections.ridersManagement}
                        onToggle={() => toggleSection("ridersManagement")}
                      />
                    </>
                  )}

                  <hr className="border border-lGrey" />
                  <div className="rounded-lg">
                    <SidebarSection
                      title="Settings"
                      links={[
                        canViewCategories ? { to: "/admin/category/list", label: "Categories" } : null,
                        canViewTickets ? { to: "/admin/help/list", label: "Help Center" } : null,

                        {
                          to: "/admin/account/2fa/update/status",
                          label: " 2FA Status",
                        },
                        canViewAdmins ? {
                          to: "/admin/account/notification/status",
                          label: "Notification",
                        } : null,
                        {
                          to: "/admin/password/change",
                          label: "Change Password",
                        },
                        canViewAppSetting ? { to: "/admin/appsettings/list", label: "App Settings" } : null,
                        canViewSlider ? { to: "/admin/slider/list", label: "Slider" } : null,
                      ].filter(Boolean)}
                      isOpen={openSections.accountSettings}
                      onToggle={() => toggleSection("accountSettings")}
                    />
                    <hr className="border border-lGrey" />
                    <div className="rounded-lg mx-4 mt-2">
                      <LogoutButton />
                    </div>
                  </div>
                </aside>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col flex-1 w-full overflow-hidden mb-8">
          <main className="relative flex-1 overflow-y-auto focus:outline-none">
            <div className="py-4">
              <div
                className="px-4 mx-auto max-w-7xl sm:px-6 md:px-8 ml-[3%] pr-[8%]"
                style={{ backgroundColor: !IsDashBoard ? "white" : '' }}
              >
                {/* main content here */}
                <div className="container-fluid">
                  <main>{children}</main>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default MainLayouts;
