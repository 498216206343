import { useState, useEffect } from "react";
import MainLayouts from "../../Layout/MainLayouts";
import { ReactComponent as ArrowLeftSquare } from "bootstrap-icons/icons/arrow-left-square-fill.svg";
import { ReactComponent as BiNewspaper } from "bootstrap-icons/icons/newspaper.svg";
import { ReactComponent as RightArrow } from "bootstrap-icons/icons/chevron-right.svg";

import Spinner from "../spinner/Spinner";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { ReactComponent as ChevronRightIcon } from "bootstrap-icons/icons/chevron-right.svg";
import axios from "axios";
import ApiServices from "../../api/ApiServices";
import { toast } from "react-toastify";
import { handleError } from "../../components/utils/AuthenticationCheck";
import refresh from "../../assets/refresh.svg";

const CustomerDetails = () => {
  const [customerDetails, setCustomerDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [balance, setBalance] = useState("Not available");
  const navigateTo = useNavigate();
  const location = useLocation();
  const id = location.state.id;
  // console.log(id);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const getBalance = async (user_id) => {
    setLoading(true);
    try {
      const authData = JSON.parse(localStorage.getItem("auth"));
      const token = authData?.token;
      if (!token) {
        throw new Error("No access token found.");
      }

      const response = await axios.get(ApiServices.getBalance, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        params: { user_id: id },
        withCredentials: true,
      });

      const { status, message, data } = response.data;

      if (status === 200) {
        // console.log("this is is the customer data from state", data);
        setBalance(data || "");
        toast.success(message, {
          toastId: "customer-details-fetched-successfully",
        });
      } else {
        handleError({ response: { status, data: { message } } }, navigateTo);
      }
    } catch (error) {
      handleError(error, navigateTo);
      // console.error("Error fetching customer details:", error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getBalance();
  }, []);

  useEffect(() => {
    const fetchAdminDetails = async () => {
      setLoading(true);
      try {
        const authData = JSON.parse(localStorage.getItem("auth"));
        const token = authData?.token;
        if (!token) {
          throw new Error("No access token found.");
        }

        const response = await axios.get(ApiServices.getCustomersDetails, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          params: { user_id: id },
          withCredentials: true,
        });

        const { status, message, data } = response.data;

        if (status === 200) {
          // console.log("this is is the customer data from state", data);
          setCustomerDetails(data || {});
          toast.success(message, {
            toastId: "customer-details-fetch-successfully",
          });
        } else {
          handleError({ response: { status, data: { message } } }, navigateTo);
        }
      } catch (error) {
        handleError(error, navigateTo);
        // console.error("Error fetching customer details:", error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchAdminDetails();
  }, [id]);

  return (
    <div>
      <MainLayouts>
        <div className="relative overflow-x-auto sm:rounded-lg py-6 w-full">
          {/* <h1 className="text-dash md:text-lg">Customer Details</h1>
           */}
          <section
            className="w-full pl-[3%] pr-[3%] pt-3 my-3"
            style={{ backgroundColor: "#F7F7F7" }}
          >
            <nav className="bg-gray-200 py-0 px-4 rounded-lg ">
              <ol className="list-none p-0 inline-flex mb-2">
                <li className="flex items-center">
                  <p className="text-blue-500 hover:text-blue-600 text-darkGreen3 pr-2">
                    Customer
                  </p>
                  <ChevronRightIcon />
                </li>
                <li className="flex items-center">
                  <p className="text-orange1 ">Details</p>
                </li>
              </ol>
            </nav>
          </section>

          {loading ? (
            <Spinner loading={loading} />
          ) : (
            <section className="text-gray-600 body-font">
              <div className="container px-1 py-1 mx-auto">
                <div className="flex flex-wrap -m-2">
                  <div className="p-2 w-full">
                    <div className="h-full flex   border-grey3 border items-center p-4 rounded-lg">
                      {/* <img
                        alt="team"
                        className="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4 hidden md:block "
                        src={`https://ui-avatars.com/api/?name=${encodeURIComponent(
                          customerDetails.firstname
                            ? customerDetails.firstname
                            : "Not Available"
                        )}`}
                      /> */}
                      <div className="flex-grow hidden md:block ">
                        <h2 className="text-dash title-font font-medium">
                          {customerDetails.lastname && customerDetails.firstname
                            ? `${customerDetails.lastname} ${customerDetails.firstname}`
                            : "Not Available"}
                        </h2>
                        <p className="text-gray-500 hidden md:block ">
                          <span className="text-gray-500">Status : </span>
                          <span className="text-dash">
                            {customerDetails.status
                              ? customerDetails.status
                              : "Not Available"}
                          </span>
                        </p>
                        <p className="text-gray-500 hidden md:block ">
                          <span className="text-gray-500">
                            Virtual Account :{" "}
                          </span>
                          <span className="text-dash">
                            {customerDetails.virtual_acct
                              ? customerDetails.virtual_acct
                              : "Not Available"}
                          </span>
                        </p>
                      </div>
                      <Link
                        to="/admin/customer"
                        className="w-full sm:w-auto text-dGrey3 bg-darkGreen3 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                      >
                        <span className="flex justify-center sm:justify-start mx-2">
                          <ArrowLeftSquare className="mr-2" />
                          Back
                        </span>
                      </Link>
                    </div>
                  </div>

                  <div className="px-2  w-full">
                    <div className="h-full flex items-center border-dash border p-2  rounded-lg border-l-4">
                      <div className="w-full  pb-6">
                        <div className="flex  border-gray-200 py-2 ">
                          <span className="text-gray-500">Customer Type</span>
                          <span className="ml-auto text-dash">
                            {customerDetails.user_type
                              ? customerDetails.user_type
                              : "Not Available"}
                          </span>
                        </div>

                        <div className="flex border-t border-grey3 py-2 ">
                          <span className="text-gray-500">Email Address</span>
                          <span className="ml-auto text-dash">
                            {customerDetails.email
                              ? customerDetails.email
                              : "Not Available"}
                          </span>
                        </div>
                        <div className="flex border-t border-grey3 py-2 ">
                          <span className="text-gray-500">Phone</span>
                          <span className="ml-auto text-dash">
                            {customerDetails.phoneNumber
                              ? customerDetails.phoneNumber
                              : "Not Available"}
                          </span>
                        </div>

                        <div className="flex border-t border-grey3 py-2">
                          <span className="text-gray-500">Address </span>
                          <span className="ml-auto text-dash">
                            &nbsp;
                            {customerDetails.address
                              ? customerDetails.address
                              : "Not Available"}
                          </span>
                        </div>

                        <div className="flex border-t border-grey3 py-2 justify-between">
                          <span className="text-gray-500">Wallet balance </span>
                          {/* <span className="ml-auto text-dash">
                            &nbsp;
                            {customerDetails.address
                              ? customerDetails.address
                              : "Not Available"}
                          </span> */}
                          <div className="text-[16px] leading-[25.6px] lg:text-xl whitespace-nowrap flex items-center gap-2 lg:gap-5 text-dash">
                            <img
                              src={refresh}
                              alt=""
                              className={`cursor-pointer ${
                                loading && "animate-spin"
                              } `}
                              onClick={getBalance}
                            />
                            &#8358;{balance}
                          </div>
                        </div>

                        <div className="flex border-t border-grey3 py-2 relative">
                          <span className="text-gray-500">
                            {" "}
                            Customer Transactions
                          </span>
                          <button
                            className="ml-auto sm:w-auto text-dGrey3 bg-orange1  focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                            onClick={toggleDropdown}
                          >
                            <span className="flex justify-center sm:justify-start mx-2">
                              {/* <ArrowLeftSquare className="mr-2" /> */}
                              <BiNewspaper className="mr-2" />
                              View
                            </span>
                          </button>

                          {isDropdownOpen && (
                            <div
                              className="absolute right-0 -top-[400%] mt-2 w-64 rounded-lg shadow-md ring-black ring-opacity-5 !bg-dGrey3  transition-transform transform scale-100 opacity-100"
                              style={{ zIndex: 50 }}
                            >
                              <div className="">
                                <div
                                  className="flex justify-between items-center mb-2 hover:bg-dGrey1  w-full p-4 gap-x-2 cursor-pointer"
                                  onClick={() => {
                                    navigateTo(
                                      "/admin/customer/physical/history",
                                      { state: { id }, replace: true }
                                    );
                                  }}
                                >
                                  <BiNewspaper className="" />
                                  <p className="flex-1">Physical Products</p>
                                  <RightArrow className="" />
                                </div>
                                <div
                                  className="flex justify-between items-center mb-2 hover:bg-dGrey1 p-4  gap-x-2 cursor-pointer"
                                  onClick={() => {
                                    navigateTo(
                                      "/admin/customer/bills/history",
                                      { state: { id }, replace: true }
                                    );
                                  }}
                                >
                                  <BiNewspaper className="" />
                                  <p className="flex-1">Bills</p>
                                  <RightArrow className="" />
                                </div>
                                <div
                                  className="flex justify-between items-center mb-2 hover:bg-dGrey1 p-4  gap-x-2 cursor-pointer"
                                  onClick={() => {
                                    navigateTo(
                                      "/admin/customer/wallet/history",
                                      { state: { id }, replace: true }
                                    );
                                  }}
                                >
                                  <BiNewspaper className="" />
                                  <p className="flex-1">wallet history</p>
                                  <RightArrow className="" />
                                </div>

                                <p className="text-sm text-gray-700 text-darkGreen3"></p>
                              </div>
                              <hr className="my-2 border-grey3" />
                              <div className="py-2 px-4 flex items-center cursor-pointer hover:bg-gray-100">
                                {/* <BoxArrowRight className="mr-3 text-sm text-dash5" />
                  <LogoutButton /> */}
                              </div>
                            </div>
                          )}
                        </div>

                        {/* <div className="flex border-t border-gray-200 py-2">
                          <span className="text-gray-500">
                            Payment Confirmation
                          </span>
                          <span className="ml-auto text-dash">
                            {customerDetails.paymentConfirmed
                              ? customerDetails.paymentConfirmed.toUpperCase()
                              : "Not Available"}
                          </span>
                        </div>
                        <div className="flex border-t border-gray-200 py-2">
                          <span className="text-gray-500">Payment date</span>
                          <span className="ml-auto text-dash">
                            {customerDetails.paymentDate
                              ? customerDetails.paymentDate
                              : "Not Available"}
                          </span>
                        </div> */}

                        {/* <div className="flex ">
                          <p className="text-dash title-font font-medium text-2xl">
                            &#8358;
                          </p>
                          <span className="title-font font-medium text-2xl">
                            {customerDetails.amount
                              ? customerDetails.amount
                              : "Not Available"}
                          </span>
                        </div>
                        <div className="flex justify-end">
                          <span
                            className="title-font font-medium "
                            style={{
                              backgroundColor: "#1D9E34",
                              padding: "7px",
                              color: "white",
                            }}
                          >
                            Product Discount : &nbsp;
                            {customerDetails.discount
                              ? customerDetails.discount
                              : "Not Available"}
                          </span>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}
        </div>
      </MainLayouts>
    </div>
  );
};

export default CustomerDetails;
