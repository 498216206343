import { useState, useEffect } from "react";
import MainLayouts from "../../Layout/MainLayouts";
import { ReactComponent as ArrowLeftCircleFill } from "bootstrap-icons/icons/arrow-left-circle-fill.svg";
import { ReactComponent as ArrowRightCircleFill } from "bootstrap-icons/icons/arrow-right-circle-fill.svg";
import { ReactComponent as ChevronRightIcon } from "bootstrap-icons/icons/chevron-right.svg";
import { ReactComponent as List } from "bootstrap-icons/icons/list.svg";
import Spinner from "../spinner/Spinner";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import ApiServices from "../../api/ApiServices";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { handleError } from "../../components/utils/AuthenticationCheck";

const RemovePermissionFromAdmin = () => {
  const [permissions, setPermissions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [permissionsPerPage] = useState(10);
  const [filterText, setFilterText] = useState("");
  const [selectedPermissions, setSelectedPermissions] = useState(new Set());
  const navigateTo = useNavigate();
  const location = useLocation();
  const admin = location.state?.admin;

  const [admin_id] = useState(admin?.admin_id || "");

  const fetchPermissions = async () => {
    try {
      const authData = JSON.parse(localStorage.getItem("auth"));
      const token = authData?.token;
      if (!token) throw new Error("No access token found.");

      const response = await axios.get(ApiServices.getAdminPermissionsByid, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        params: { admin_id: admin.admin_id },
        withCredentials: true,
      });

      const { status, data, message } = response.data;
      if (status === 200) {
        setPermissions(data);
        setSelectedPermissions(
          new Set(data.map((permission) => permission.permission_id))
        );
        // console.log("Fetched permissions:", data);
      } else {
        handleError({ response: { status, data: { message } } }, navigateTo);
      }
    } catch (error) {
      handleError(error, navigateTo);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPermissions();
  }, [admin.admin_id, navigateTo]);

  const handleRemoveSelectedPermissions = async () => {
    const permissionsToDelete = permissions
      .filter(
        (permission) => !selectedPermissions.has(permission.permission_id)
      )
      .map((permission) => permission.permission_id);

    console.log("Permissions to delete:", permissionsToDelete);
    //check if notthing is selcted before delete
    if (permissionsToDelete.length === 0) {
      toast.info("Please select at least one permission to delete.");
      return;
    }

    const result = await Swal.fire({
      title: "Are you sure?",
      text: "This action will remove the selected permissions.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, remove them!",
    });

    if (result.isConfirmed) {
      await handleDeletePermissions(permissionsToDelete);
    }
  };

  // const handleDeletePermissions = async (permissionsToDelete) => {
  //   const formData = {
  //     // permission_ids: permissionsToDelete,
  //     permission_ids: Array.from(permissionsToDelete),
  //     admin_id: admin_id,
  //   };
  //   console.log(formData);
  //   try {
  //     setLoading(true);
  //     const authData = JSON.parse(localStorage.getItem("auth"));
  //     const token = authData?.token;
  //     if (!token) throw new Error("No access token found.");

  //     const response = await axios.post(
  //       ApiServices.removePermissionsFromAdmin,
  //       formData,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //           "Content-Type": "application/json",
  //         },
  //         withCredentials: true,
  //       }
  //     );

  //     const { status, data, message } = response.data;
  //     if (status === 200) {
  //       toast.success(message);
  //       // fetchPermissions(); // Refetch permissions after delete
  //       await fetchPermissions();
  //       console.log("Current permissions after delete:", permissions);
  //     } else {
  //       handleError({ response: { status, data: { message } } }, navigateTo);
  //     }
  //   } catch (error) {
  //     handleError(error, navigateTo);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const handleDeletePermissions = async (permissionsToDelete) => {
    try {
      setLoading(true);
      const authData = JSON.parse(localStorage.getItem("auth"));
      const token = authData?.token;
      if (!token) throw new Error("No access token found.");

      // for (const permissionId of permissionsToDelete) {
        const formData = {
          permission_ids: permissionsToDelete,
          admin_id: admin_id,
        };
        //  console.log("Form data to delete permission:", formData);

        const response = await axios.post(
          ApiServices.removePermissionsFromAdmin,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        );

        const { status, message } = response.data;
        if (status === 200) {
          toast.success(message);
        } else {
          handleError({ response: { status, message } }, navigateTo);
          // break; // Stop on first error
        }
      // }

      await fetchPermissions(); // Refresh permissions after deletion
    } catch (error) {
      // console.error("Error during deletion:", error);
      handleError(error, navigateTo);
    } finally {
      setLoading(false);
    }
  };

  const handleCheckboxChange = (permissionId) => {
    const updatedSelected = new Set(selectedPermissions);
    if (updatedSelected.has(permissionId)) {
      updatedSelected.delete(permissionId); // Remove permission if unchecked
    } else {
      updatedSelected.add(permissionId); // Add permission if checked
    }
    setSelectedPermissions(updatedSelected);
  };

  const handleFilter = (e) => {
    setFilterText(e.target.value);
  };

  const filteredPermissions = permissions.filter((item) =>
    item.name.toLowerCase().includes(filterText.toLowerCase().trim())
  );

  const indexOfLastPermission = currentPage * permissionsPerPage;
  const indexOfFirstPermission = indexOfLastPermission - permissionsPerPage;
  const currentPermissions = filteredPermissions.slice(
    indexOfFirstPermission,
    indexOfLastPermission
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const hasNextPage = () => indexOfLastPermission < filteredPermissions.length;
  const hasPrevPage = () => currentPage > 1;

  // Function to format permission text
  const formatPermissionText = (text) => {
    // Replace underscores with spaces
    let formattedText = text.replace(/_/g, " ");

    // Capitalize the first letter of each word
    formattedText = formattedText
      .toLowerCase()
      .replace(/\b\w/g, (char) => char.toUpperCase());

    return formattedText;
  };

  // Function to parse the permission string into action and resource
  const parsePermission = (permission) => {
    const [resource, action] = permission.split(".");
    return { resource, action };
  };

  return (
    <div>
      <MainLayouts>
        <div className="relative overflow-x-auto sm:rounded-lg py-6 w-full">
          <section
            className="w-full pl-[3%] pr-[3%] pt-3 my-3"
            style={{ backgroundColor: "#F7F7F7" }}
          >
            <nav className="bg-gray-200 py-0 px-1 rounded-lg ">
              <ol className="list-none p-0 inline-flex mb-2">
                <li className="flex items-center">
                  <p className="text-blue-500 hover:text-blue-600 text-red1 pl-1">
                    Remove Permission From
                  </p>
                  <ChevronRightIcon />
                </li>
                <li className="flex items-center">
                  <p className="text-gray-600 text-black1">
                    <span className="font-medium pl-1">{admin.fullname}</span>
                  </p>
                  <ChevronRightIcon />
                </li>
                <li className="flex items-center">
                  <p className="text-gray-600 text-orange1">
                    <span className="font-medium pl-1">{admin.email}</span>
                  </p>
                </li>
              </ol>
            </nav>
          </section>
          <div className="pb-4 bg-white dark:bg-gray-900 flex flex-wrap items-center justify-between gap-4">
            <div className="relative w-full sm:w-1/2 mb-4 sm:mb-0">
              <input
                type="search"
                className="block w-full px-3 py-2 text-md text-gray-900 border border-gray-300 rounded-lg bg-gray-50 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                placeholder="Search"
                value={filterText}
                onChange={handleFilter}
              />
            </div>
          </div>
          {loading ? (
            <Spinner loading={loading} />
          ) : (
            <section className="text-gray-600 body-font">
              <div className="container px-1 py-2 mx-auto">
                <div className="flex flex-wrap -m-2">
                  {currentPermissions.length > 0 ? (
                    currentPermissions.map((permission, index) => {
                      const { resource, action } = parsePermission(
                        permission.name
                      );
                      return (
                        <div
                          key={index}
                          className="p-2 lg:w-1/3 md:w-1/2 w-full"
                        >
                          <div className="h-full flex items-center border-darkGreen3 border p-4 rounded-lg">
                            <div className="flex-grow">
                              <h2 className="flex items-center justify-between title-font font-medium">
                                <p className="text-black1">
                                  Permission for <span>{action}</span> <br />
                                  <span className="text-darkGreen3">
                                    {formatPermissionText(resource)}
                                  </span>
                                </p>
                                <input
                                  type="checkbox"
                                  className="ml-4"
                                  checked={
                                    !selectedPermissions.has(
                                      permission.permission_id
                                    )
                                  }
                                  onChange={() =>
                                    handleCheckboxChange(
                                      permission.permission_id
                                    )
                                  }
                                />
                              </h2>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="w-full py-6 text-center">
                      <p>No permissions available.</p>
                    </div>
                  )}
                </div>
                <div className="flex flex-col gap-3 sm:flex-row justify-between mt-4">
                  <div className="flex items-center gap-3">
                    <button
                      type="button"
                      disabled={!hasPrevPage()}
                      onClick={() => paginate(currentPage - 1)}
                      className={`text-dGrey3 bg-black1 hover:bg-darkGreen2 focus:outline-none font-medium rounded-full text-sm px-5 py-2 text-center inline-flex items-center gap-1 ${
                        !hasPrevPage() && "opacity-50"
                      }`}
                    >
                      <ArrowLeftCircleFill />
                      Previous
                    </button>
                    <button
                      type="button"
                      disabled={!hasNextPage()}
                      onClick={() => paginate(currentPage + 1)}
                      className={`text-dGrey3 bg-black1 hover:bg-darkGreen2 focus:outline-none font-medium rounded-full text-sm px-5 py-2 text-center inline-flex items-center gap-1 ${
                        !hasNextPage() && "opacity-50"
                      }`}
                    >
                      Next
                      <ArrowRightCircleFill />
                    </button>
                  </div>
                  <div className="flex flex-col gap-4 md:flex-row md:gap-4 md:justify-end mt-4">
                    <Link
                      to="/admin/view/list"
                      className="text-dGrey3 py-3 bg-black1 hover:bg-blue-800 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 text-center flex items-center"
                    >
                      <List className="mr-2" />
                      Back To List
                    </Link>
                    <button
                      type="button"
                      onClick={handleRemoveSelectedPermissions}
                      className={`text-white bg-red1 text-dGrey3 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded font-medium ${
                        loading ? "btn-loading" : "btn-primary"
                      }`}
                      disabled={loading}
                    >
                      {loading ? "Loading..." : "Remove Selected Permissions"}
                    </button>
                  </div>
                </div>
              </div>
            </section>
          )}
        </div>
      </MainLayouts>
    </div>
  );
};

export default RemovePermissionFromAdmin;
