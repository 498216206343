import React, { useState, useEffect, useContext } from "react";
import MainLayouts from "../../Layout/MainLayouts";
import { ReactComponent as PersonPlus } from "bootstrap-icons/icons/person-plus.svg";
import { ReactComponent as PeopleFill } from "bootstrap-icons/icons/people-fill.svg";
import { ReactComponent as Trash } from "bootstrap-icons/icons/trash3.svg";
import { ReactComponent as BookmarkDash } from "bootstrap-icons/icons/bookmark-dash.svg";
import { ReactComponent as ChevronRightIcon } from "bootstrap-icons/icons/chevron-right.svg";
import { ReactComponent as CartFill } from "bootstrap-icons/icons/cart-fill.svg";
import { ReactComponent as PersonVcard } from "bootstrap-icons/icons/person-vcard.svg";
import { Link } from "react-router-dom";
import CurrencyFormatter from "../../components/utils/CurrencyFormatter";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar,
} from "recharts";
import axios from "axios";
import ApiServices from "../../api/ApiServices";
import { handleError } from "../../components/utils/AuthenticationCheck";
import { useNavigate } from "react-router-dom";
import DashboardCards from "../../components/DashboardCards";
import { toast } from "react-toastify";
import Spinner from "../spinner/Spinner";
import { AuthContext } from "../../AuthProvider/AuthProvider";

// import {Envelope} from '/Images/Envelope.svg';
// import SpreadSheet from '/Images/Spreadsheet.svg';
// import Cart from '/Images/Cart.svg';
const SalesCards = ({ icon, Heading, Value }) => {
  return (
    <div>
      <div className="flex justify-between items-start  space-x-1 ">
        <img src={icon} alt="icon" height={30} width={30} />

        <h3 className="text-grey1 text-base">{Heading}</h3>
      </div>
      <h1 className="lg:text-2xl gilroy-bold w-full ml-2">{Value}</h1>
    </div>
  );
};
const Dashboard = () => {
  const [loading, setLoading] = useState(false);
  const navigateTo = useNavigate();
  const [data, setData] = useState(null);

  const { permissions } = useContext(AuthContext);
  // useEffect(() => {
  //   console.log('Current User Permissions:', permissions);
  // }, [permissions]);

  const hasPermission = (requiredPermission) => {
    if (!permissions || !Array.isArray(permissions)) {
      return false;
    }
    return permissions.some(permission => permission?.name === requiredPermission);
  };

  // Assign permission to variable to make condition on sidebar
  const canViewWallet = hasPermission('wallet.view');

  useEffect(() => {
    const fetchCategoryList = async () => {
      setLoading(true);
      try {
        const authData = JSON.parse(localStorage.getItem("auth"));
        const token = authData?.token;
        if (!token) throw new Error("No access token found.");

        const response = await axios.get(ApiServices.getDashboard, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          withCredentials: true,
        });

        const { status, message, data } = response.data;
        //console.log("this is the category lis data", data);
        if (status === 200) {
          setData(data);
          // toast.success(message, { toastId: "data-fetch-success" });
        } else {
          handleError({ response: { status, data: { message } } }, navigateTo);
        }
      } catch (error) {
        handleError(error, navigateTo);
      } finally {
        setLoading(false);
      }
    };

    fetchCategoryList();
  }, []);



  return (
    <div>
      <MainLayouts>
        {/* three column card */}
        <section className="container">
          {loading ? (
            <Spinner loading={loading} />
          ) : (
            <div className="container  pt-2 pb-72 pr-6 pl-6 max-lg:pr-2 max-lg:pl-2 max-lg:pt-2 ">
              <div className="Card-parents w-full min-h-10 py-3 px-0 grid grid-cols-3 gap-4 ">
                {canViewWallet && (
                  <div className="col-span-3 md:col-span-2 lg:col-span-1 ">
                    <DashboardCards
                      Heading="Total sales"
                      Icon={CartFill}
                      Amount={CurrencyFormatter(data?.total_sales)}
                    />
                  </div>
                )}
                <div className="col-span-3 md:col-span-2 lg:col-span-1 ">
                  <DashboardCards
                    Heading="Total Vendors"
                    Icon={PersonVcard}
                    Amount={` ${data?.number_of_vendors}`}
                  />
                </div>

                <div className="col-span-3 md:col-span-2 lg:col-span-1 ">
                  <DashboardCards
                    Heading="Total Customers"
                    Icon={PeopleFill}
                    Amount={` ${data?.number_of_users}`}
                  />
                </div>
              </div>

              {/* graphs */}
              {canViewWallet && (
                <div className="Orders section mt-2 w-full grid grid-cols-4 min-h-20 rounded-sm pt-2 pb-4 max-sm:pt-5 max-sm:pb-4  max-md:pt-3 max-md:pb-7  ">
                  <div className="col-span-4 bg-[#fff] rounded-md pt-10 pb-10 max-lg:pt-5  max-lg:pb-5 pr-4">
                    <div className="w-full overflow-x-auto">
                      <ResponsiveContainer width="100%" height={300}>
                        <BarChart data={data?.weekly_data.slice().reverse()}>
                          <CartesianGrid strokeDasharray="2 2" />
                          <XAxis dataKey="day" />
                          <YAxis />
                          <Tooltip />
                          <Legend />
                          <Bar dataKey="total_quantity" fill="#257f34" />
                        </BarChart>
                      </ResponsiveContainer>
                    </div>
                    {/* 
                 <h1 className="ml-14 lg:text-2xl gilroy-bold ">
                     Last 7 Days Sales
                  </h1>  */}

                    <div className="Sales-cards flex justify-start items-center ml-14  space-x-5 mt-4 max-lg:flex-wrap max-lg:ml-12  max-lg:flex-col max-lg:items-start max-lg:justify-center max-lg:space-x-0">
                      <SalesCards
                        Heading={"Stock sold"}
                        icon={"/Images/Envelope.svg"}
                        Value={data?.number_of_sold_stock}
                      />
                      <SalesCards
                        Heading={"Sales Today"}
                        icon={"/Images/Cart.svg"}
                        Value={data?.number_of_sales_today}
                      />
                      <SalesCards
                        Heading={"Number of Products"}
                        icon={"/Images/Spreadsheet.svg"}
                        Value={data?.number_of_stock_products}
                      />
                      <SalesCards
                        Heading={"Active stock"}
                        icon={"/Images/Envelope.svg"}
                        Value={data?.number_of_active_stock}
                      />
                    </div>
                  </div>
                </div>
              )}
              {/* no of orders */}
              <div className="Orders section w-full grid grid-cols-4 bg-[#fff] min-h-20 rounded-sm pt-3 pb-8 px-6 max-sm:pt-5 max-sm:pb-4 max-sm:px-3  max-md:pt-9 max-md:pb-7 max-md:px-5 mt-10 ">
                <div className="max-md:col-span-12 col-span-12 ">
                  <h1 className="max-md:text-xl lg:text-3xl mb-3">
                    Most Recent orders
                  </h1>
                  <p className="max-md:text-sm lg:text-base text-grey2 mb-4">
                    Below shows a list of most recent orders
                  </p>

                  <div className="flex flex-col space-y-4 divide-y divide-grey3 w-full max-lg:w-full max-lg:space-y-6">
                    {data?.recent_orders?.slice(0, 6).map((data2, index) => (
                      <div
                        className="h-4  w-full flex justify-between items-start max-lg:items-center py-4 max-sm:py-2 space-x-2 "
                        key={index}
                      >
                        <img
                          src="/Images/Cart.svg"
                          alt="icon"
                          height={30}
                          width={30}
                        />
                        <p className="flex-1 text-sm gilroy-regular">
                          {data2.address}
                        </p>
                        <p className="text-grey1 text-sm">
                          ₦ {data2.total_amount}
                        </p>
                      </div>
                    ))}
                  </div>

                  <div className="flex justify-end w-11/12">
                    <Link to="/admin/order/list" className="mt-5 max:lg-mt-10 px-2 py-1 text-md border border-darkGreen2 hover:bg-darkGreen2 hover:text-[#fff] text-darkGreen2 bg-[#fff] rounded-md shadow-md">
                      View More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          )}
        </section>
      </MainLayouts>
    </div>
  );
};

export default Dashboard;
