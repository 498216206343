const BASE_URL = "https://joatech.hordecall.net/api/";
// const BASE_URL = "http://127.0.0.1:8000/api/";

const API_URLS = {
  //============================ admin management =============================
  adminLogin: `${BASE_URL}auth/admin/login`,
  verifyOtp: `${BASE_URL}auth/admin/verify-otp`,
  forgetPassword: `${BASE_URL}auth/admin/forgot-password`,
  resetPassword: `${BASE_URL}admin/reset-password`,
  updatePassword: `${BASE_URL}admin/change-password`,
  addAdmin: `${BASE_URL}admin/create`,
  updateAdminTwoFa: `${BASE_URL}admin/update-two-factor`,
  adminNotifcationStatus: `${BASE_URL}admin/update-notification`,
  getAdmin: `${BASE_URL}admin`,
  UpdateAdmin: `${BASE_URL}admin/update`,
  getAdminById: `${BASE_URL}admin/show`,
  getAdminActivityLog: `${BASE_URL}admin/all-activities`,

  // =============== get permissions =============================
  getPermissions: `${BASE_URL}admin/permissions`,
  updatePermissions: `${BASE_URL}admin/permissions/update`,
  assignPermissions: `${BASE_URL}admin/permissions/assign`,
  getAdminPermissionsByid: `${BASE_URL}admin/permissions/view`,
  removePermissionsFromAdmin: `${BASE_URL}admin/permissions/revoke`,

  // ====================== end of permissions =================
  // =============== get roles =============================
  getRoles: `${BASE_URL}admin/roles`,
  createRole: `${BASE_URL}admin/roles/create`,
  UpdateRole: `${BASE_URL}admin/roles/update`,
  grantPermissionToRole: `${BASE_URL}admin/roles/permissions`,
  assignPermissionToRole: `${BASE_URL}admin/roles/assign`,
  getRolesPermission: `${BASE_URL}admin/roles/show`,
  deleteRoles: `${BASE_URL}admin/roles/delete`,
  // ====================== end of roles =================
  // =========================== categories ====================================-
  getCategory: `${BASE_URL}admin/categories`,
  updateCategory: `${BASE_URL}admin/categories/update`,
  addCategory: `${BASE_URL}admin/categories/create`,
  removeCategory: `${BASE_URL}admin/categories/delete`,

  // =========================== sub categories ===============================
  getSubCategoryById: `${BASE_URL}admin/sub-category`,
  updateSubCategory: `${BASE_URL}admin/sub-category/update`,
  addSubCategory: `${BASE_URL}admin/sub-category/create`,
  removeSubCategory: `${BASE_URL}admin/sub-category/delete`,
  // =========================== products ====================================-
  getProducts: `${BASE_URL}admin/products`,
  getProductsById: `${BASE_URL}admin/products/show`,
  addProducts: `${BASE_URL}admin/products/create`,
  updateProducts: `${BASE_URL}admin/products/update`,
  removeProducts: `${BASE_URL}admin/products/delete`,
  addProductsImages: `${BASE_URL}admin/products/image/add`,
  removeProductsImages: `${BASE_URL}admin/products/image/delete`,
  // =========================== products category ====================================-
  getProductsBySubCategoryId: `${BASE_URL}admin/product-category`,
  createProductCategory: `${BASE_URL}admin/product-category/create`,
  updateProductCategory: `${BASE_URL}admin/product-category/update`,
  removeProductsCategory: `${BASE_URL}admin/product-category/delete`,
  // =========================== products stock ====================================-
  getAllProductsStock: `${BASE_URL}admin/product/stocks`,
  getProductsStockById: `${BASE_URL}admin/product/stocks/show`,
  createProductStock: `${BASE_URL}admin/product/stocks/create`,
  updateProductStock: `${BASE_URL}admin/product/stocks/update`,
  removeProductStock: `${BASE_URL}admin/product/stocks/delete`,
  addImagesToProductStock: `${BASE_URL}admin/product/stocks/image/add`,
  removeImagesProductStock: `${BASE_URL}admin/product/stocks/image/delete`,
  addProductStockSupply: `${BASE_URL}admin/product/stocks/supply`,
  addProductStockVariant: `${BASE_URL}admin/product/stocks/variant/create`,
  updateProductStockVariant: `${BASE_URL}admin/product/stocks/variant/update`,
  removeProductStockVariant: `${BASE_URL}admin/product/stocks/variant/delete`,
  addProductStockVariantImages: `${BASE_URL}admin/product/stocks/image/variant/add`,

  //================================= store ========================================
  getAllStore: `${BASE_URL}admin/stores`,
  createStore: `${BASE_URL}admin/stores/create`,
  getAllStoreById: `${BASE_URL}admin/stores/show`,
  updateStore: `${BASE_URL}admin/stores/update`,
  removeStore: `${BASE_URL}admin/stores/delete`,

  // // =========================== customer ====================================-
  getCustomers: `${BASE_URL}admin/customers`,
  getCustomersDetails: `${BASE_URL}admin/customers/show`,
  getWalletTransactions: `${BASE_URL}admin/customers/wallet/transactions`,
  getPhysicalProducts: `${BASE_URL}admin/customers/physical-products`,
  getOtherBills: `${BASE_URL}admin/customers/bill-products`,
  getBalance: `${BASE_URL}admin/customers/balance`,

  // =========================== vendor ====================================-
  getAllVendor: `${BASE_URL}admin/vendors`,
  getVendorById: `${BASE_URL}admin/vendors/show`,
  updateVendor: `${BASE_URL}admin/vendors/update`,
  getVendorBalance: `${BASE_URL}admin/vendors/wallet/balance`,
  getVendorTransactionHistory: `${BASE_URL}admin/vendors/wallet/transactions`,

  // getPayment: `${BASE_URL}payment`,
  // // =========================== messages ====================================-
  // getMessages: `${BASE_URL}message`,
  // //============================= faq =================================
  // faqs: `${BASE_URL}list`,
  // //===========================-  fulfiled =============================
  // getFullfilled: `${BASE_URL}fulfiled`,
  // //===========================-  return  =============================
  // getReturn: `${BASE_URL}return`,
  // //===========================-  return  =============================
  // getHelpPending: `${BASE_URL}helpPending`,
  // //===========================-  help center  =========================
  // getHelpCenter: `${BASE_URL}helpCenter`,
  //========================= orders ======================================
  getAllOrders: `${BASE_URL}admin/orders`,
  getOrdersById: `${BASE_URL}admin/orders/show`,
  updateOrders: `${BASE_URL}admin/orders/update`,

  // ======================  Bills Management =============================
  getBillsProduct: `${BASE_URL}admin/bills`,
  getBillsProductsForSwitch: `${BASE_URL}admin/bills/getproducts`,
  changeProductStatus: `${BASE_URL}admin/bills/changeproductstatus`,
  getBillers: `${BASE_URL}admin/bills/getbillers`,
  addBillers: `${BASE_URL}admin/bills/addbiller`,
  editBillers: `${BASE_URL}admin/bills/editbiller`,
  searchBills: `${BASE_URL}admin/bills/search`,
  switch: `${BASE_URL}admin/bills/switch`,
  getSwitchers: `${BASE_URL}admin/bills/getswitchers`,
  addSwitchers: `${BASE_URL}admin/bills/addswitcher`,
  // ========================= flash sales =========================
  getAllFlashsales: `${BASE_URL}admin/flash-sales`,
  createFlashsales: `${BASE_URL}admin/flash-sales/create`,
  updateFlashsales: `${BASE_URL}admin/flash-sales/update`,
  removeFlashsales: `${BASE_URL}admin/flash-sales/delete`,
  // ======================== product property ========================
  getProductProperty: `${BASE_URL}admin/properties`,
  createProductProperty: `${BASE_URL}admin/properties/create`,
  updateProductProperty: `${BASE_URL}admin/properties/update`,
  // ==================== admin ticket response ========================
  getAllTicket: `${BASE_URL}admin/tickets`,
  getTicketById: `${BASE_URL}admin/tickets/show`,
  updateTicket: `${BASE_URL}admin/tickets/update`,
  // =============== ticket message ================================
  sendMessageToTicket: `${BASE_URL}admin/message/send`,
  getMessageToTicketById: `${BASE_URL}admin/message/show`,
  //================= Dashboard =====================================
  getDashboard: `${BASE_URL}admin/dashboard`,

  //=======================- app setting for website ===============================--
  getAllAppSettings: `${BASE_URL}admin/app-settings`,
  UpdateAppSettings: `${BASE_URL}admin/app-settings/update`,
  AllSlider: `${BASE_URL}admin/app-settings/sliders`,
  FrontSlider: `${BASE_URL}admin/app-settings/get-sliders`,
  AddSlider: `${BASE_URL}admin/app-settings/slider`,
  deleteSlider: `${BASE_URL}admin/app-settings/slider`,
  //=======================- rider management ===============================--
  getAllRiders: `${BASE_URL}admin/riders`,
  ridersDetails: `${BASE_URL}admin/riders/show`,
  EditRiders: `${BASE_URL}admin/riders/update`,
  //=======================- dashboard management ===============================--
  // getDashboard: `${BASE_URL}admin/dashboard`,
};

export default API_URLS;
